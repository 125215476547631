import { Grid, Skeleton } from "@mui/material";

const CredentialsManagementLoader = () => {
	return (
		<>
			<Grid container spacing={2} sx={{ py: 4 }}>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={120}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default CredentialsManagementLoader;
