import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";

const ConnectorModalHeaderLoader = () => {
	return (
		<>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					flexWrap: "wrap",
					alignItems: "center",
				}}
			>
				<Box sx={{ textAlign: "center" }}>
					<Skeleton
						sx={{ borderRadius: "5px" }}
						variant="rounded"
						height={60}
						width={200}
					/>
					<Typography
						sx={{
							py: 1,
						}}
					>
						Tasks Completed
					</Typography>
				</Box>
				<Box sx={{ textAlign: "center" }}>
					<Skeleton
						sx={{ borderRadius: "5px" }}
						variant="rounded"
						height={60}
						width={200}
					/>
					<Typography
						sx={{
							py: 1,
						}}
					>
						Status
					</Typography>
				</Box>
				<Box sx={{ textAlign: "center" }}>
					<Skeleton
						sx={{ borderRadius: "5px" }}
						variant="rounded"
						height={60}
						width={200}
					/>
					<Typography
						sx={{
							py: 1,
						}}
					>
						Phase
					</Typography>
				</Box>
				<Box sx={{ textAlign: "center" }}>
					<Skeleton
						sx={{ borderRadius: "5px" }}
						variant="rounded"
						height={60}
						width={200}
					/>
					<Typography
						sx={{
							py: 1,
						}}
					>
						Est. Completion
					</Typography>
				</Box>
			</Box>
		</>
	);
};

export default ConnectorModalHeaderLoader;
