import { Fragment, useEffect, useState } from "react";
import {
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Divider,
	Box,
	Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ConnectionsLoader from "./ConnectionsLoader";

const Connections = ({ fields }) => {
	const [connectionsInfo, setConnectionsInfo] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		const timeoutId = setTimeout(() => {
			setConnectionsInfo({
				endpoints: [
					{
						field_name: "",
						endpoint_path_url: "",
						parameter: "",
						method: [],
					},
				],
			});
			setIsLoading(false);
		}, 500);
		return () => clearTimeout(timeoutId);
	}, []);

	const handleAddField = () => {
		setConnectionsInfo((prev) => ({
			...prev,
			endpoints: [
				...prev.endpoints,
				{
					field_name: "",
					endpoint_path_url: "",
					parameter: "",
					method: [],
				},
			],
		}));
	};

	const handleRemoveField = (index) => {
		setConnectionsInfo((prev) => {
			const newEndpoints = [...prev.endpoints];
			if (newEndpoints.length > 1) {
				newEndpoints.splice(index, 1);
			}
			return { ...prev, endpoints: newEndpoints };
		});
	};

	const changeConnectionField = (event, index, field) => {
		const value = event.target.value;
		setConnectionsInfo((prev) => {
			const newEndpoints = [...prev.endpoints];
			newEndpoints[index] = {
				...newEndpoints[index],
				[field]: typeof value === "string" ? value.split(",") : value,
			};
			return { ...prev, endpoints: newEndpoints };
		});
	};

	return (
		<>
			{isLoading ? (
				<ConnectionsLoader />
			) : (
				<>
					<Grid container spacing={2} sx={{ px: { md: 2 } }}>
						{fields.map((field) => (
							<Grid
								key={field.connector_object_field_id}
								item
								xs={12}
								md={3}
								sx={{ gap: 1 }}
							>
								<TextField
									label={field.field_nm}
									variant="outlined"
									size="small"
									value={
										connectionsInfo?.[field.field_api_nm]
									}
									onChange={(e) =>
										setConnectionsInfo((prev) => ({
											...prev,
											[field.field_api_nm]:
												e.target.value,
										}))
									}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",

										padding: 0,
									}}
								/>
							</Grid>
						))}
					</Grid>
					<Divider sx={{ px: { md: 2 }, fontSize: 16, my: 2 }}>
						FIELDS
					</Divider>
					<Box sx={{ textAlign: "center" }}>
						<Button
							variant="contained"
							sx={{
								height: 40,
								minWidth: 150,
								backgroundColor: "var(--data-con-blue)",
							}}
							onClick={handleAddField}
						>
							ADD FIELD
						</Button>
					</Box>
					<Grid container spacing={2} sx={{ px: { md: 2 }, my: 2 }}>
						{connectionsInfo?.endpoints.map((endpoint, index) => (
							<Fragment key={index}>
								<Grid item xs={12} md={3} sx={{ gap: 1 }}>
									<TextField
										label="Field Name"
										variant="outlined"
										size="small"
										value={endpoint.field_name}
										onChange={(e) =>
											changeConnectionField(
												e,
												index,
												"field_name"
											)
										}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											sx: {
												height: 37,
												padding: 1,
											},
											inputProps: {
												sx: {
													padding: 0,
													height: "100%",
													display: "flex",
													alignItems: "center",
													fontSize: "0.875rem",
												},
											},
										}}
										sx={{
											width: "100%",

											padding: 0,
										}}
									/>
								</Grid>
								<Grid item xs={12} md={3} sx={{ gap: 1 }}>
									<TextField
										label="Endpoint Path URL"
										variant="outlined"
										size="small"
										value={endpoint.endpoint_path_url}
										onChange={(e) =>
											changeConnectionField(
												e,
												index,
												"endpoint_path_url"
											)
										}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											sx: {
												height: 37,
												padding: 1,
											},
											inputProps: {
												sx: {
													padding: 0,
													height: "100%",
													display: "flex",
													alignItems: "center",
													fontSize: "0.875rem",
												},
											},
										}}
										sx={{
											width: "100%",

											padding: 0,
										}}
									/>
								</Grid>
								<Grid item xs={12} md={3} sx={{ gap: 1 }}>
									<TextField
										label="Parameter(s)"
										variant="outlined"
										size="small"
										value={endpoint.parameter}
										onChange={(e) =>
											changeConnectionField(
												e,
												index,
												"parameter"
											)
										}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											sx: {
												height: 37,
												padding: 1,
											},
											inputProps: {
												sx: {
													padding: 0,
													height: "100%",
													display: "flex",
													alignItems: "center",
													fontSize: "0.875rem",
												},
											},
										}}
										sx={{
											width: "100%",

											padding: 0,
										}}
									/>
								</Grid>
								<Grid item xs={12} md={2.5} sx={{ gap: 1 }}>
									<FormControl
										size="small"
										sx={{
											minWidth: "100%",
											maxWidth: "100%",
										}}
									>
										<InputLabel
											id="methods"
											sx={{
												fontSize: "0.875rem",
												textAlign: "left",
											}}
										>
											HTTP Method(s)
										</InputLabel>
										<Select
											size="small"
											multiple
											value={endpoint.method}
											onChange={(e) =>
												changeConnectionField(
													e,
													index,
													"method"
												)
											}
											label="HTTP Method(s)"
											labelId="methods"
											renderValue={(selected) =>
												selected.join(", ")
											}
											sx={{
												fontSize: "0.875rem",
												textAlign: "left",
											}}
										>
											<MenuItem value={"GET"}>
												GET
											</MenuItem>
											<MenuItem value={"POST"}>
												POST
											</MenuItem>
											<MenuItem value={"PUT"}>
												PUT
											</MenuItem>
											<MenuItem value={"PATCH"}>
												PATCH
											</MenuItem>
											<MenuItem value={"DELETE"}>
												DELETE
											</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={0.5}>
									{connectionsInfo?.endpoints.length > 1 && (
										<IconButton
											disableRipple
											sx={{ pl: 0 }}
											aria-label="delete"
											onClick={() =>
												handleRemoveField(index)
											}
										>
											<DeleteIcon />
										</IconButton>
									)}
								</Grid>
							</Fragment>
						))}
					</Grid>
				</>
			)}
		</>
	);
};

export default Connections;
