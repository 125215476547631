import {
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import CredentialsManagementLoader from "./CredentialsManagementLoader";

const CredentialsManagement = () => {
	const [credentialsManagementInfo, setCredentialsManagementInfo] =
		useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		const timeoutId = setTimeout(() => {
			setCredentialsManagementInfo({
				username: "",
				password: "",
				verification_type: "",
				account_verification: "",
				client_id: "",
				client_secret: "",
				access_token: "",
				access_token_expiration: "",
				security_notes: "",
			});
			setIsLoading(false);
		}, 500);
		return () => clearTimeout(timeoutId);
	}, []);
	return (
		<>
			<Divider
				sx={{
					textTransform: "uppercase",
					fontSize: { xs: "14px", sm: "17px", md: "19.2px" },
				}}
			>
				Credentials Management
			</Divider>

			{isLoading ? (
				<CredentialsManagementLoader />
			) : (
				<Grid container spacing={2} sx={{ py: 4 }}>
					<Grid item xs={12} md={3} sx={{ gap: 1 }}>
						<TextField
							label="Username/Email"
							variant="outlined"
							size="small"
							value={credentialsManagementInfo?.username}
							onChange={(e) =>
								setCredentialsManagementInfo((prev) => ({
									...prev,
									username: e.target.value,
								}))
							}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								sx: {
									height: 37,
									padding: 1,
								},
								inputProps: {
									sx: {
										padding: 0,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",

								padding: 0,
							}}
						/>
					</Grid>
					<Grid item xs={12} md={3} sx={{ gap: 1 }}>
						<TextField
							label="Password"
							variant="outlined"
							size="small"
							value={credentialsManagementInfo?.password}
							onChange={(e) =>
								setCredentialsManagementInfo((prev) => ({
									...prev,
									password: e.target.value,
								}))
							}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								sx: {
									height: 37,
									padding: 1,
								},
								inputProps: {
									sx: {
										padding: 0,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",

								padding: 0,
							}}
						/>
					</Grid>
					<Grid item xs={12} md={3} sx={{ gap: 1 }}>
						<FormControl
							size="small"
							sx={{
								minWidth: "100%",
							}}
						>
							<InputLabel
								id="verification_type"
								sx={{
									fontSize: "0.875rem",
									textAlign: "left",
								}}
							>
								Account Verification Type
							</InputLabel>
							<Select
								labelId="verification_type"
								value={
									credentialsManagementInfo?.verification_type
								}
								onChange={(e) =>
									setCredentialsManagementInfo((prev) => ({
										...prev,
										verification_type: e.target.value,
									}))
								}
								label="Account Verification Type"
								sx={{
									fontSize: "0.875rem",
									textAlign: "left",
								}}
							>
								<MenuItem value={""}>
									<em>None</em>
								</MenuItem>
								<MenuItem value={"user-pass"}>
									Username - Password
								</MenuItem>
								<MenuItem value={"token"}>Token</MenuItem>
								<MenuItem value={"oauth"}>OAuth</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={3} sx={{ gap: 1 }}>
						<TextField
							label="Account Verification"
							variant="outlined"
							size="small"
							value={
								credentialsManagementInfo?.account_verification
							}
							onChange={(e) =>
								setCredentialsManagementInfo((prev) => ({
									...prev,
									account_verification: e.target.value,
								}))
							}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								sx: {
									height: 37,
									padding: 1,
								},
								inputProps: {
									sx: {
										padding: 0,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",

								padding: 0,
							}}
						/>
					</Grid>
					<Grid item xs={12} md={3} sx={{ gap: 1 }}>
						<TextField
							label="Client ID"
							variant="outlined"
							size="small"
							value={credentialsManagementInfo?.client_id}
							onChange={(e) =>
								setCredentialsManagementInfo((prev) => ({
									...prev,
									client_id: e.target.value,
								}))
							}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								sx: {
									height: 37,
									padding: 1,
								},
								inputProps: {
									sx: {
										padding: 0,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",

								padding: 0,
							}}
						/>
					</Grid>
					<Grid item xs={12} md={3} sx={{ gap: 1 }}>
						<TextField
							label="Client Secret"
							variant="outlined"
							size="small"
							value={credentialsManagementInfo?.client_secret}
							onChange={(e) =>
								setCredentialsManagementInfo((prev) => ({
									...prev,
									client_secret: e.target.value,
								}))
							}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								sx: {
									height: 37,
									padding: 1,
								},
								inputProps: {
									sx: {
										padding: 0,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",

								padding: 0,
							}}
						/>
					</Grid>
					<Grid item xs={12} md={3} sx={{ gap: 1 }}>
						<TextField
							label="Access Token"
							variant="outlined"
							size="small"
							value={credentialsManagementInfo?.access_token}
							onChange={(e) =>
								setCredentialsManagementInfo((prev) => ({
									...prev,
									access_token: e.target.value,
								}))
							}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								sx: {
									height: 37,
									padding: 1,
								},
								inputProps: {
									sx: {
										padding: 0,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",

								padding: 0,
							}}
						/>
					</Grid>
					<Grid item xs={12} md={3} sx={{ gap: 1 }}>
						<TextField
							label="Access Token Expiration"
							variant="outlined"
							size="small"
							value={
								credentialsManagementInfo?.access_token_expiration
							}
							onChange={(e) =>
								setCredentialsManagementInfo((prev) => ({
									...prev,
									access_token_expiration: e.target.value,
								}))
							}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								sx: {
									height: 37,
									padding: 1,
								},
								inputProps: {
									sx: {
										padding: 0,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",

								padding: 0,
							}}
						/>
					</Grid>
					<Grid item xs={12} sx={{ gap: 1 }}>
						<TextField
							label="Security Notes"
							variant="outlined"
							multiline
							rows={3}
							value={credentialsManagementInfo?.security_notes}
							onChange={(e) =>
								setCredentialsManagementInfo((prev) => ({
									...prev,
									security_notes: e.target.value,
								}))
							}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								sx: {
									padding: 1,
								},
								inputProps: {
									sx: {
										padding: 0,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",
								padding: 0,
							}}
						/>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default CredentialsManagement;
