import axios from 'axios';
const { REACT_APP_API_URL } = process.env;

const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: REACT_APP_API_URL + "/api/"
})

const AxiosService = {
    get: (path) => {
        return axiosInstance.get(path)
            .catch(error => {
            })
    },
    post: (path, payload,) => {
        return axiosInstance.post(path, payload)
            .then(res => res.data)
            .catch(error => {
                const response = error.response;
                if (response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // TODO: block this log from production
                    switch (+response.status) {
                        case 400:
                            // Request failed
                            return response.data
                        case 401:
                            // Login unauthorized
                            return response.data
                        case 403:
                            // User doesn't have permissions
                            if (response.data.redirect === true) {
                                window.history.back();
                            } else if (response.data.redirect === false) {
                                return response.data
                            } else {
                                window.location.href = response.data.redirect
                            }
                            break;
                        case 404:
                            // Not found
                            break;
                        default:
                            break;
                    }

                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                } else {
                    // Something happened in setting up the request that triggered an Error
                }
            })
    },
    put: (path, payload) => {
        return axiosInstance.put(path, payload)
            .then(res => res.data)
            .catch(error => {
                return Promise.reject(error)
            })
    },
    delete: (path) => {
        return axiosInstance.delete(REACT_APP_API_URL + `/api/${path}`)
            .then(res => res.data)
            .catch(error => {
                return Promise.reject(error)
            })
    }
}

const ApiService = {
    get: (path) => {
        return fetch(REACT_APP_API_URL + `/api/${path}`, {
            method: "GET",
            credentials: 'include'
        }).then(res => {
            if (res.ok) {
                return res.json()
            } else {
                if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_MARKETING_SITE !== "unset" ? process.env.REACT_APP_MARKETING_SITE + "/login" : "/login"
                }
            }
        })
            .catch(err => handleErr(err))
    },
    getFileDownload: (path) => {
        return fetch(REACT_APP_API_URL + `/api/${path}`, {
            method: "GET",
            credentials: 'include',
        }).then(res  => {
            if (res.ok) {
                res.blob().then((blob) => {
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;

                    link.setAttribute(
                        'download',
                        res.headers.get("x-file-name"),
                    );
                
                    // Append to html link element page
                    document.body.appendChild(link);
                
                    // Start download
                    link.click();
                
                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                });
            } else {
                if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_MARKETING_SITE !== "unset" ? process.env.REACT_APP_MARKETING_SITE + "/login" : "/login"
                }
                return Promise.reject(res)
            }
        })
        .catch(err => handleErr(err))
    },
    post: (path, payload) => {
        return fetch(REACT_APP_API_URL + `/api/${path}`, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => {
            if (res.ok) {
                return res.json()
            } else {
                if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_MARKETING_SITE !== "unset" ? process.env.REACT_APP_MARKETING_SITE + "/login" : "/login"
                }
                return Promise.reject(res)
            }
        })
            .catch(err => handleErr(err))
    },
    postFileUpload: (path, file) => {
        const formData = new FormData()
        formData.append('fileUpload', file);
        return fetch(REACT_APP_API_URL + `/api/${path}`, {
            method: "POST",
            body: formData,
            credentials: 'include'
        }).then(res => {
            if (res.ok) {
                return res.json()
            } else {
                if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_MARKETING_SITE !== "unset" ? process.env.REACT_APP_MARKETING_SITE + "/login" : "/login"
                }
                return Promise.reject(res)
            }
        })
            .catch(err => handleErr(err))
    },
   
    put: (path, payload) => {
        return fetch(REACT_APP_API_URL + `/api/${path}`, {
            method: "PUT",
            body: JSON.stringify(payload),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => {
            if (res.ok) {
                return res.json();
            } else {
                if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_MARKETING_SITE !== "unset" ? process.env.REACT_APP_MARKETING_SITE + "/login" : "/login"
                }
                return Promise.reject(res)
            }
        })
            .catch(err => handleErr(err))
    },
    delete: (path) => {
        return fetch(REACT_APP_API_URL + `/api/${path}`, {
            method: "DELETE",
            credentials: 'include'
        }).then(res => {
            if (res.ok) {
                return res.json()
            } else {
                if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_MARKETING_SITE !== "unset" ? process.env.REACT_APP_MARKETING_SITE + "/login" : "/login"
                }
            }
        })
            .catch(err => handleErr(err))
    }
}

const handleErr = (err) => {
    return Promise.reject(err)
}

export const UserAPIService = {
    login: (credentials) => {
        return AxiosService.post('users/login', credentials);
    },
    register: (credentials) => {
        return ApiService.post('users/register', credentials);
    },
    anonymousRegister: (credentials) => {
        return ApiService.post('users/anonymous-register', credentials);
    },
    registerFromDataConnector: (credentials) => {
        return ApiService.post('users/register-data-connector', credentials);
    },
    changePassword: (payload) => {
        return AxiosService.post('users/change-password', payload)
    },
    resetPassword: (payload) => {
        return ApiService.post('users/reset', payload);
    },
    forgotPassword: (form) => {
        return ApiService.post("users/forgot", form)
    },
    resetForgottenPassword: (form) => {
        return ApiService.post("users/reset-password", form)
    },
    update: (id, payload) => {
        return ApiService.put(`users/update/${id}`, payload);
    },
    saveUser: (payload) => {
        return ApiService.post('users/save', payload);
    },
    getUserWithPermission: (id) => {
        return ApiService.get(`users/list-with-permission`);
    },
    getUserDetail: () => {
        return ApiService.get(`users/detail`);
    },
    checkUserAvailability: (email) => {
        return ApiService.get(`users/by-email?email=${email}`);
    },
    getPaymentMethod: () => {
        return ApiService.get(`users/payment-method`);
    },
    updateUserInformation: (id, payload) => {
        return AxiosService.put(`users/information/${id}`, payload);
    },
    updateDefaultOrganization: (id, payload) => {
        return AxiosService.put(`users/update-default-organization/${id}`, payload);
    },
    deleteUser: (id) => {
        return AxiosService.delete(`users/${id}`);
    },
    resendVerificationEmail: (id) => {
        return AxiosService.put(`users/${id}/resend-verification-email`, {});
    },
    deleteLogout: () => {
        return ApiService.delete(`users/logout`);
    },
    createPaymentMethod: (payload) => {
        return ApiService.post("users/customer-payment-method", payload)
    },
    updateCustomerPaymentMethod: ({ cpmid, data }) => {
        return ApiService.put(`users/update-payment-method?cpmid=${cpmid}`, data)
    },
    customerPaymentMethodDetail: (cpmid) => {
        return ApiService.get(`users/customer-payment-method?cpmid=${cpmid}`)
    },
    removePaymentMethod: (cpmid) => {
        return ApiService.delete(`users//remove-payment-method/?cpmid=${cpmid}`)
    },
    setDefaultPaymentMethod: (payload) => {
        return ApiService.post('users/default-payment-method', payload)
    },
    cancelMembership: (id, payload) => {
        return ApiService.put(`organization/deactivate/${id}`, payload)
    },
    reactivateMembership: (id) => {
        return ApiService.put(`organization/activate/${id}`)
    },
    addNewUserOrganization: (payload) => {
        return AxiosService.post('users/add-new-user-organization', payload)
    }
}

export const ConnectorAPIService = {
	getConnections: (id) => {
		return ApiService.get(`connector/${id}/connection`);
	},
	getConnectorConnections: (id) => {
		if (!id) return;
		return ApiService.get(`connector/${id}/connections`);
	},
	getAllConnections: () => {
		return ApiService.get(`connector/all-connections`);
	},
	getConnectorPipelines: (id) => {
		return ApiService.get(`connector/${id}/pipelines`);
	},
	disableConnectorPipelines: (id) => {
		return ApiService.put(`connector/${id}/disable-pipelines`);
	},
	getConnectionPipelines: ({ connector, connection }) => {
		return ApiService.get(
			`connector/${connector}/connection/${connection}/pipelines`
		);
	},
	updateDefaultConnection: ({
		connector_id,
		connector_credential_id,
		checked,
	}) => {
		return ApiService.put(
			`connector/${connector_id}/connection/${connector_credential_id}/change-default-status?default=${checked}`
		);
	},
	getPipelineDetails: (id) => {
		return ApiService.get(`job/${id}/connect`);
	},
	getOauthLink: (id, source) => {
		return ApiService.get(
			`connector/oauth/link?connector_id=${id}&source=${source}`
		);
	},
	getConnectors: () => {
		return ApiService.get(`connector`);
	},
	customAuth: (id, credentials) => {
		return ApiService.post(`connector/${id}/lambda?action=3`, credentials);
	},
	testConnectorConnection: (id, credentialID) => {
		return ApiService.get(
			`connector/${id}/lambda?action=0&credentialID=${credentialID}`
		);
	},
	connectorTest: (id, payload) => {
		return ApiService.post(`connector/${id}/test`, payload);
	},
	getFields: (id, credentialID, objectID, options) => {
		return ApiService.post(
			`connector/${id}/lambda?action=1&credentialID=${credentialID}`,
			{ options, object_id: objectID }
		);
	},
	getPreview: (id, credentialID, objectID, mapping, options) => {
		return ApiService.post(
			`connector/${id}/lambda?action=2&credentialID=${credentialID}`,
			{ mapping, options, object_id: objectID }
		);
	},
	getAuthenticationMethods: (id) => {
		return ApiService.get(`connector/${id}/authentication/methods`);
	},
	getAuthenticationDetails: (id) => {
		return ApiService.get(`connector/${id}/authentication/details`);
	},
	postConnection: (id, payload) => {
		return AxiosService.post(`connector/${id}/connection`, payload);
	},
	updateConnection: ({ connector_id, connector_credential_id, payload }) => {
		return ApiService.put(
			`connector/${connector_id}/connection/${connector_credential_id}`,
			payload
		);
	},
	deleteConnection: ({ connector_id, connector_credential_id }) => {
		return ApiService.delete(
			`connector/${connector_id}/connection/${connector_credential_id}`
		);
	},
	createConnectorRequest: (payload) => {
		return ApiService.post(`connector/request`, payload);
	},
	connectorEarlyAdopter: (payload) => {
		return ApiService.post(`connector/early-adopter`, payload);
	},
	uploadFile: (file) => {
		return ApiService.postFileUpload(`connector/upload-file`, file);
	},
	downloadRunFile: (id) => {
		return ApiService.getFileDownload(`connector/download-run-file/${id}`);
	},
	searchConnector: (name) => {
		return ApiService.get(`connector/search?query=${name}`);
	},
	buildConnector: (payload) => {
		return ApiService.post("connector/orchestrator-event-create", payload);
	},
	getConnectorAuthenticationTypes: (connector_id) => {
		return ApiService.get(`connector/${connector_id}/authentication-types`);
	},
	getConnectorObject: (connector_id) => {
		return ApiService.get(`connector/${connector_id}/objects`);
	},
	getConnectorField: (object_id) => {
		return ApiService.get(`connector/object/${object_id}/fields`);
	},
	getConnectorById: (connector_id) => {
		return ApiService.get(`connector/${connector_id}`);
	},
	getConnectorOrchestratorEvent: (connector_id, event_type) => {
		return ApiService.get(
			`connector/${connector_id}/orchestrator-event?event_type=${event_type}`
		);
	},
};

export const JiraAPIService = {
	getTasks: (ConnectorID, queryParams = {}) => {
		const queryString = new URLSearchParams(queryParams).toString();
		return ApiService.get(
			`jira/task/connector/${ConnectorID}?${queryString}`
		);
	},
	getStatus: () => {
		return ApiService.get(`jira/status`);
	},
	getUsers: () => {
		return ApiService.get(`jira/user`);
	},
};

export const AccountAPIService = {
    getAccounts: (id) => {
        return ApiService.get(`accounts?connector_id=${id}`);
    },
    getSaved: () => {
        return ApiService.get(`accounts/saved`);
    },
    cancelOauth: () => {
        return ApiService.delete(`accounts/saved`);
    },
    saveAccount: (payload) => {
        return ApiService.post(`accounts/saved`, payload);
    },
    testAccountConnection: (credentialID, connectorID) => {
        return ApiService.get(`accounts/test?credential_id=${credentialID}&connector_id=${connectorID}`);
    },
    getEmailPreference: () => {
        return ApiService.get('email-preference/by-user');
    },
    updateEmailPreference: (data) => {
        return ApiService.put('email-preference/update', data);
    },
    getBilling: ({ startDate, endDate }) => {
        return ApiService.get(`billing?startdate=${startDate}&enddate=${endDate}`)
    },
    getDateList: () => {
        return ApiService.get(`billing/date-list`)
    }
}

export const DocumentationApiService = {
    getDocumentations: () => {
        return ApiService.get('documentation')
    }
}

export const ConfigureAPIService = {
    getJobDetails: (id) => {
        return ApiService.get(`configure/job/${id}`);
    },
    postJob: (payload) => {
        return ApiService.post('configure/job', payload);
    },
    putJob: (payload, id = null) => {
        return ApiService.put(`configure/job/${(id) ? id : ""}`, payload);
    },
    postInvoke: (id) => {
        return ApiService.post(`job/run/${id}`);
    },
    getLogs: (id) => {
        return ApiService.get(`configure/logs/${id}`);
    },
    postScheduleTask: (id) => {
        return ApiService.post(`job/${id}`);
    },
    putSchedule: (payload, scheduleID = -1) => {
        return ApiService.put(`configure/schedule/${(scheduleID > -1) ? scheduleID : ""}`, payload);
    },
    putETLJobDateRange: (id, payload) => {
        return ApiService.put(`configure/date-range/${id || ""}`, payload);
    },
    putNotification: (payload, id = null) => {
        return ApiService.put(`configure/notification/${id || ""}`, payload);
    },
    deleteJob: (id) => {
        return ApiService.delete(`configure/job/${id}`);
    },
    postMapping: (payload) => {
        return ApiService.post(`configure/mapping`, payload);
    },
    putMapping: (payload, id) => {
        return ApiService.put(`configure/mapping/${id}`, payload);
    },
    putPipelineChanges: (payload, id) => {
        return ApiService.put(`configure/pipeline/${id}`, payload);
    }
}

export const PipelineAPIService = {
    getPipelines: () => {
        return ApiService.get('pipelines');
    },
    getLoadHistory: (jobID) => {
        return ApiService.get(`pipelines/loadHistory?PipelineID=${jobID}`, jobID)
    },
    enableDisablePipelines: ({ id, status }) => {
        return ApiService.put(`pipelines/${id}/${status}`)
    },
}

export const EventLogAPIService = {
    addPipelineClicked: () => {
        return ApiService.get('events/add-pipeline');
    },
    postEvent: (eventMessage, params) => {
        return ApiService.post('events/', {eventMessage, params});
    }
}

export const JobAPIService = {
    getLogs: (id) => {
        return ApiService.get(`job/logs/${id}`);
    }
}

export const AdminAPIService = {
    getConnectors: () => {
        return ApiService.get('admin/connectors')
    },
    updateConnector: (row) => {
        return ApiService.put('admin/connector', row)
    }
}

export const GlobalAPIService = {
    setConnectorState: (state) => {
        return ApiService.post('connector/state', state)
    },
    getConnectorState: () => {
        return ApiService.get('connector/state');
    },
    getLogTypes: () => {
        return ApiService.get('log-type')
    },
    getEventTypes: () => {
        return ApiService.get('event-type')
    },
    getLogStages: () => {
        return ApiService.get('log-stage')
    }
}

export const PermissionAPIService = {
    getPermissions: () => {
        return ApiService.get('permission')
    },
}


export const OrganizationAPIService = {
    getOrganizations: () => {
        return ApiService.get(`users/organizations`)
    },
    assignUserToOrganization: (id) => {
        return ApiService.put(`users/assign-organization/${id}`)
    },
    getAllOrganizationUsers: () => {
        return ApiService.get(`organization/users`);
    },
    getAllOrganization: () => {
        return ApiService.get(`organization`);
    },
    getOrganizationDetail: () => {
        return ApiService.get(`organization/details`);
    },
    updateOrganizationDetail: (payload) => {
        return AxiosService.put(`organization/update`, payload);
    },
    checkOrganizationAvailability: (organization) => {
        return ApiService.get(`organization/by-name?name=${organization}`);
    },
    getOrganizationLogs: ({
        startDate = '',
        endDate = '',
        user = '',
        eventType = '',
        logTypeId = '',
        pipelineId = '',
        connectorId = '',
        logStageId = ''
    }) => {
        return ApiService.get(`job/organization-logs?startDate=${startDate}&endDate=${endDate}&eventType=${eventType}&user=${user}&logTypeId=${logTypeId}&pipelineId=${pipelineId}&connectorId=${connectorId}&logStageId=${logStageId}`)
    }
}

export const FeatureRequestAPIService = {
    getFeatureTypes: () => {
        return ApiService.get(`feature-type`)
    },
    getFeatureRequests: (id = '') => {
        return ApiService.get(`feature-request?featureTypeId=${id}`)
    },
    createFeatureRequest: (payload) => {
        return ApiService.post(`feature-request`, payload)
    },
    reviewFeatureRequest: ({ id, data }) => {
        return ApiService.put(`feature-request/review/${id}`, data)
    },
    postVote: ({ id, data }) => {
        return ApiService.post(`feature-request/${id}/vote`, data)
    },
}

function getFileExtensionContentType(extension) {
    switch (extension) {
        case 'csv':
        case 'txt':
            return 'text/csv';
        case 'xlsx':
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        case 'pdf':
            return 'application/pdf';
        case 'json':
            return 'application/json';
        default:
            return '';
    }
}