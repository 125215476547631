import { createContext, useContext, useState } from "react";

const ConnectorModalContext = createContext();

export function useConnectorModalContext() {
	return useContext(ConnectorModalContext);
}

export default function ConnectorModalProvider({ children }) {
	const [selectedConnector, setSelectedConnector] = useState({});
	const [appImage, setAppImage] = useState("");
	const [hasActiveTask, setHasActiveTask] = useState(false);
	const [activeTaskList, setActiveTaskList] = useState([]);

	//Dev TAB
	const [connectorInfo, setConnectorInfo] = useState({});
	const [apiGeneralInfo, setApiGeneralInfo] = useState(null);

	return (
		<ConnectorModalContext.Provider
			value={{
				selectedConnector,
				setSelectedConnector,
				appImage,
				setAppImage,
				hasActiveTask,
				setHasActiveTask,
				//Dev TAB
				connectorInfo,
				setConnectorInfo,
				apiGeneralInfo,
				setApiGeneralInfo,
				activeTaskList,
				setActiveTaskList,
			}}
		>
			{children}
		</ConnectorModalContext.Provider>
	);
}
