import ConnectorInfo from "./ConnectorInfo";
import ApiGeneralInfo from "./ApiGeneralInfo";
import Authentication from "./Authentication";
import ApiEndPoints from "./ApiEndPoints";
import { useEffect } from "react";
import { ConnectorAPIService } from "../../../config/api-service";
import { useConnectorModalContext } from "../connectors.context";

const DevTab = () => {
	const {
		selectedConnector,
		setSelectedConnector,
		setConnectorInfo,
		setApiGeneralInfo,
		hasActiveTask,
		setActiveTaskList,
	} = useConnectorModalContext();

	useEffect(() => {
		const getData = async () => {
			let connectorInfoData = {};
			try {
				const [connectorResponse, orchestratorEventResponse] =
					await Promise.all([
						ConnectorAPIService.getConnectorById(
							selectedConnector.ConnectorID
						),
						ConnectorAPIService.getConnectorOrchestratorEvent(
							selectedConnector.ConnectorID,
							"METADATA"
						),
					]);

				if (connectorResponse) {
					setSelectedConnector(connectorResponse.connector);
					connectorInfoData.connector_name =
						connectorResponse.connector.ConnectorNM;
					connectorInfoData.description =
						connectorResponse.connector.ConnectorDSC;
					setApiGeneralInfo((prev) => ({
						...prev,
						documentation_api_url:
							connectorResponse.connector.DocumentationURL,
					}));
				}

				if (orchestratorEventResponse) {
					setApiGeneralInfo((prev) => ({
						...prev,
						version_changelog_url:
							orchestratorEventResponse.dataList[0]
								?.version_change_log,
						github_url:
							orchestratorEventResponse.dataList[0]
								?.api_github_url,
						release_date:
							orchestratorEventResponse.dataList[0]?.release_date,
						support_contact_email:
							orchestratorEventResponse.dataList[0]?.email,
						support_contact_phone:
							orchestratorEventResponse.dataList[0]?.phone,
						terms_of_service_url:
							orchestratorEventResponse.dataList[0]
								?.terms_of_service,
						privacy_policy_url:
							orchestratorEventResponse.dataList[0]
								?.privacy_policy,
						supported_environments:
							orchestratorEventResponse.dataList[0]
								?.supported_environments,
						supported_data_formats:
							orchestratorEventResponse.dataList[0]
								?.supported_data_formats,
						last_update_date:
							orchestratorEventResponse.dataList[0]?.last_updated,
						license_type:
							orchestratorEventResponse.dataList[0]?.license,
						community_support_url:
							orchestratorEventResponse.dataList[0]
								?.community_support,
						known_issues_url:
							orchestratorEventResponse.dataList[0]?.known_issues,
					}));

					connectorInfoData.home_url =
						orchestratorEventResponse.dataList[0]?.home_url;
					connectorInfoData.category =
						orchestratorEventResponse.dataList[0]?.category;
					connectorInfoData.industry =
						orchestratorEventResponse.dataList[0]?.industry;
					connectorInfoData.tags =
						orchestratorEventResponse.dataList[0]?.tags;
					connectorInfoData.type =
						orchestratorEventResponse.dataList[0]?.type;

					setActiveTaskList(
						orchestratorEventResponse.dataList[0]?.active_task_list
					);
				}
				setConnectorInfo(connectorInfoData);
			} catch (error) {}
		};
		getData();
		let intervalId;
		if (hasActiveTask) {
			intervalId = setInterval(() => {
				getData();
			}, 10000);
		}
		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [
		hasActiveTask,
		selectedConnector.ConnectorID,
		setSelectedConnector,
		setConnectorInfo,
		setApiGeneralInfo,
		setActiveTaskList,
	]);
	return (
		<>
			<ConnectorInfo />
			<ApiGeneralInfo />
			<Authentication />
			<ApiEndPoints />
		</>
	);
};

export default DevTab;
