import { Box, Divider, Grid, Skeleton } from "@mui/material";

const ConnectionsLoader = () => {
	return (
		<>
			<Grid container spacing={2} sx={{ px: { md: 2 } }}>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
			</Grid>
			<Divider sx={{ px: { md: 2 }, fontSize: 16, my: 2 }}>
				FIELDS
			</Divider>
			<Box sx={{ textAlign: "center", mb: 2 }}>
				<Skeleton
					sx={{ mx: "auto", borderRadius: "5px" }}
					variant="rounded"
					height={40}
					width={200}
				/>
			</Box>
			<Grid container spacing={2} sx={{ px: { md: 2 } }}>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default ConnectionsLoader;
