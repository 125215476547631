import ApplicationDetails from "./ApplicationDetails";
import CredentialsManagement from "./CredentialsManagement";
import DeveloperAccountSetup from "./DeveloperAccountSetup";
import TestUserAccounts from "./TestUserAccounts";

const AppTab = () => {
	return (
		<>
			<DeveloperAccountSetup />
			<CredentialsManagement />
			<ApplicationDetails />
			<TestUserAccounts />
		</>
	);
};

export default AppTab;
