import {
	Box,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import AuthenticationLoader from "./AuthenticationLoader";
import { ConnectorAPIService } from "../../../../config/api-service";
import { useConnectorModalContext } from "../../connectors.context";

const Authentication = () => {
	const [authenticationInfo, setAuthenticationInfo] = useState(null);
	const [activeTask, setActiveTask] = useState(null);
	const [authTypes, setAuthTypes] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const { selectedConnector, activeTaskList } = useConnectorModalContext();

	useEffect(() => {
		const task = activeTaskList?.find(
			(activeTask) => activeTask.prime_jira_task_id === 7
		);
		setActiveTask(task);
	}, [activeTaskList]);

	useEffect(() => {
		setIsLoading(true);

		ConnectorAPIService.getConnectorAuthenticationTypes(
			selectedConnector.ConnectorID
		)
			.then((res) => {
				setAuthTypes(res.authenticationDetailList);
			})
			.catch((err) => {});

		const timeoutId = setTimeout(() => {
			setAuthenticationInfo({
				auth_type: "",
				auth_url: "",
				token_url: "",
				client_id: "",
				client_secret: "",
				api_key_name: "",
				redirect_url: "",
				scopes: "",
			});
			setIsLoading(false);
		}, 500);
		return () => clearTimeout(timeoutId);
	}, [selectedConnector.ConnectorID]);

	return (
		<>
			<Divider
				sx={{
					textTransform: "uppercase",
					fontSize: { xs: "14px", sm: "17px", md: "19.2px" },
				}}
			>
				AUTHENTICATION
			</Divider>
			{isLoading ? (
				<AuthenticationLoader />
			) : (
				<>
					<Box sx={{ mt: 4, textAlign: "center" }}>
						<FormControl size="small">
							<InputLabel id="auth_type">Auth Type</InputLabel>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<Select
									disabled={activeTask}
									labelId="auth_type"
									value={authenticationInfo?.auth_type}
									onChange={(e) =>
										setAuthenticationInfo((prev) => ({
											...prev,
											auth_type: e.target.value,
										}))
									}
									label="Auth Type"
									sx={{
										minWidth: 200,
									}}
								>
									<MenuItem value={""}>
										<em>None</em>
									</MenuItem>
									{authTypes.map((authType) => (
										<MenuItem
											key={
												authType.authentication_method_id
											}
											value={
												authType.authentication_method_id
											}
										>
											{authType.authentication_method_nm}
										</MenuItem>
									))}
								</Select>
							</Tooltip>
						</FormControl>
					</Box>
					<Grid container spacing={2} sx={{ py: 4 }}>
						<Grid item xs={12} md={6} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="Auth URL"
									variant="outlined"
									size="small"
									value={authenticationInfo?.auth_url}
									onChange={(e) =>
										setAuthenticationInfo((prev) => ({
											...prev,
											auth_url: e.target.value,
										}))
									}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={6} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="Token URL"
									variant="outlined"
									size="small"
									value={authenticationInfo?.token_url}
									onChange={(e) =>
										setAuthenticationInfo((prev) => ({
											...prev,
											token_url: e.target.value,
										}))
									}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",

										padding: 0,
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="Client ID"
									variant="outlined"
									size="small"
									value={authenticationInfo?.client_id}
									onChange={(e) =>
										setAuthenticationInfo((prev) => ({
											...prev,
											client_id: e.target.value,
										}))
									}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",

										padding: 0,
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="Client Secret"
									variant="outlined"
									size="small"
									value={authenticationInfo?.client_secret}
									onChange={(e) =>
										setAuthenticationInfo((prev) => ({
											...prev,
											client_secret: e.target.value,
										}))
									}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",

										padding: 0,
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="API Key Name"
									variant="outlined"
									size="small"
									value={authenticationInfo?.api_key_name}
									onChange={(e) =>
										setAuthenticationInfo((prev) => ({
											...prev,
											api_key_name: e.target.value,
										}))
									}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",

										padding: 0,
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="Redirect URL"
									variant="outlined"
									size="small"
									value={authenticationInfo?.redirect_url}
									onChange={(e) =>
										setAuthenticationInfo((prev) => ({
											...prev,
											redirect_url: e.target.value,
										}))
									}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",

										padding: 0,
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="Scopes"
									variant="outlined"
									multiline
									rows={3}
									value={authenticationInfo?.scopes}
									onChange={(e) =>
										setAuthenticationInfo((prev) => ({
											...prev,
											scopes: e.target.value,
										}))
									}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										sx: {
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
									}}
								/>
							</Tooltip>
						</Grid>
					</Grid>
				</>
			)}
		</>
	);
};

export default Authentication;
