import { Grid, Skeleton } from "@mui/material";
import React from "react";

const ConnectorsLoader = () => {
	return (
		<>
			<Grid item xs md="auto">
				<Skeleton
					variant="rounded"
					sx={{ borderRadius: "10px" }}
					width={140}
					height={95}
				/>
			</Grid>
			<Grid item xs md="auto">
				<Skeleton
					variant="rounded"
					sx={{ borderRadius: "10px" }}
					width={140}
					height={95}
				/>
			</Grid>
			<Grid item xs md="auto">
				<Skeleton
					variant="rounded"
					sx={{ borderRadius: "10px" }}
					width={140}
					height={95}
				/>
			</Grid>
			<Grid item xs md="auto">
				<Skeleton
					variant="rounded"
					sx={{ borderRadius: "10px" }}
					width={140}
					height={95}
				/>
			</Grid>
			<Grid item xs md="auto">
				<Skeleton
					variant="rounded"
					sx={{ borderRadius: "10px" }}
					width={140}
					height={95}
				/>
			</Grid>
		</>
	);
};

export default ConnectorsLoader;
