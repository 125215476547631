import {
	Box,
	Grid,
	Typography,
	TextField,
	Skeleton,
	CardMedia,
	IconButton,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import Dialog from "../../common/dialog";
import { ConnectorAPIService } from "../../../config/api-service";
import { LoadingButton } from "@mui/lab";
import ConnectorModal from "../../connectors/ConnectorModal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { styled } from "@mui/material/styles";

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

const ConnectorRequest = ({ searchText, destination = false }) => {
	const [searchResults, setSearchResults] = useState([]);
	const [showRequestConnectorModal, setShowRequestConnectorModal] =
		useState(false);
	const [showConnectorModal, setShowConnectorModal] = useState(false);
	const [newConnector, setNewConnector] = useState(null);

	const [selectedConnector, setSelectedConnector] = useState({
		title: "",
		url: "",
		logo_url: "",
		description: "",
		documentation_api_title: "",
		documentation_api_url: "",
		github_url: "",
	});

	const [loading, setLoading] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);

	const handleClose = () => {
		setShowRequestConnectorModal(false);
	};

	const handleConnectorModalClose = () => {
		setShowConnectorModal(false);
	};

	const appImage = () => {
		if (selectedConnector.logo_available) {
			return selectedConnector.logo_url;
		} else {
			return require(`../../../assets/images/app-icons/not_found.png`)
				.default;
		}
	};

	const handleModalOpen = (connector) => {
		setShowRequestConnectorModal(true);
		setSelectedConnector({
			...connector,
			title: searchText.charAt(0).toUpperCase() + searchText.slice(1),
		});
	};

	const handleSubmitConnectorRequest = async () => {
		setSubmitLoading(true);
		try {
			const response = await ConnectorAPIService.buildConnector({
				name: selectedConnector.title,
				url: selectedConnector.url,
				documentation_url: selectedConnector.documentation_api_url,
				logo: selectedConnector.logo_url,
				description: selectedConnector.description,
			});

			if (response.success) {
				setNewConnector(response.connector);
				setShowConnectorModal(true);
				setSubmitLoading(false);
				handleClose();
			}
		} catch (error) {}
	};

	useEffect(() => {
		setLoading(true);

		const checkImage = (url) => {
			return new Promise((resolve) => {
				const image = new Image();

				image.onload = function () {
					resolve(this.width > 0);
				};

				image.onerror = function () {
					resolve(false);
				};

				image.src = url;
			});
		};

		const getSearchResult = async () => {
			try {
				const result = await ConnectorAPIService.searchConnector(
					searchText
				);

				// Create an object to store the status of each image
				const resultsPromises = result.results.map(async (result) => {
					const isAvailable = await checkImage(result?.logo_url);
					return { ...result, logo_available: isAvailable };
				});

				const results = await Promise.all(resultsPromises);

				setSearchResults(results);
				setLoading(false);
			} catch {
				setLoading(false);
				setSearchResults([]);
			}
		};
		const timeoutId = setTimeout(() => {
			getSearchResult();
		}, 2500);
		return () => clearTimeout(timeoutId);
	}, [searchText]);

	return (
		<>
			{loading ? (
				<>
					<Typography
						sx={{
							textAlign: destination ? "right" : "left",
							wordBreak: "break-word",
							fontSize: { xs: "12px", md: "14px" },
							color: "var(--data-con-blue)",
						}}
					>
						Retrieving the {searchText} connector...
					</Typography>
					<Grid
						container
						spacing={2}
						sx={{
							paddingY: "20px",
							display: "flex",
							flexDirection: {
								xs: "column-reverse",
								md: "row",
							},
							justifyContent: destination
								? "flex-end"
								: "flex-start",
							textAlign: "center",
						}}
					>
						<Grid
							item
							xs={12}
							md={3}
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								mt: 1,
							}}
						>
							<Skeleton
								variant="rectangular"
								sx={{
									height: { xs: 42, sm: 80, md: 93 },
									borderRadius: "8px",
									mx: { xs: "2px", sm: "5px" },
									padding: 0,
								}}
							/>
						</Grid>
						<Grid item xs={12} md={8}>
							<Grid
								container
								spacing={1}
								sx={{ marginLeft: { xs: -1, md: 0 } }}
							>
								<Grid
									item
									xs={12}
									md={6}
									sx={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "flex-start",
										gap: { xs: 1, md: "unset" },
										alignItems: destination
											? "flex-end"
											: "flex-start",
										mt: 1.3,
									}}
								>
									<Skeleton
										sx={{
											width: "100%",
											mb: 1,
										}}
										variant="rectangular"
										height={41}
									/>
									<Skeleton
										sx={{
											width: "100%",
										}}
										variant="rectangular"
										height={41}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									md={6}
									sx={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "flex-start",
										gap: { xs: 1, md: "unset" },
										alignItems: destination
											? "flex-end"
											: "flex-start",
										mt: 1.3,
									}}
								>
									<Skeleton
										sx={{
											width: "100%",
											mb: 1,
										}}
										variant="rectangular"
										height={41}
									/>
									<Skeleton
										sx={{
											width: "100%",
										}}
										variant="rectangular"
										height={41}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</>
			) : (
				<Box
					sx={
						destination
							? {
									display: "flex",
									flexDirection: "column",
							  }
							: {}
					}
				>
					{searchResults?.map((result, index) => (
						<Fragment key={index}>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<hr
									style={{
										flexGrow: 1,
										borderTop:
											"0.5px solid var(--light-grey_hover)",
										height: "0px",
									}}
								/>
								<Typography
									component="span"
									variant="body1"
									sx={{
										padding: "0 2px",
										flexShrink: 1,
										wordBreak: "break-word",
										fontSize: {
											xs: "10px",
											sm: "12px",
										},
										fontWeight: 700,
										textAlign: "center",
									}}
								>
									CHOOSE OPTION {index + 1} FOR{" "}
									{searchText.toUpperCase()}
								</Typography>
								<hr
									style={{
										flexGrow: 1,
										borderTop:
											"0.5px solid var(--light-grey_hover)",
										height: "0px",
									}}
								/>
							</Box>

							<Grid
								container
								spacing={2}
								sx={{
									paddingY: "20px",
									display: "flex",
									flexDirection: {
										xs: "column-reverse",
										md: "row",
									},
									justifyContent: destination
										? "flex-end"
										: "flex-start",
									textAlign: "center",
								}}
							>
								<Grid
									item
									xs={12}
									md={3}
									sx={{
										display: "flex",
										flexDirection: "column",
										justifyContent: {
											xs: "center",
											md: "flex-start",
										},
									}}
								>
									<Box
										sx={{
											height: 100,
											width: 100,
											mx: { xs: "auto", md: "unset" },
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											cursor: "pointer",
											border: "2px dashed lightgray",
											borderRadius: "10px",
											p: "4px",
										}}
										onClick={() => handleModalOpen(result)}
									>
										{result?.logo_available ? (
											<CardMedia
												sx={{
													height: 100,
													width: 100,
													mx: {
														xs: "auto",
														md: "unset",
													},
													cursor: "pointer",
												}}
												image={result?.logo_url}
											/>
										) : (
											<Typography
												sx={{
													fontWeight: 700,
													color: "lightgray",
												}}
											>
												No Image
											</Typography>
										)}
									</Box>
								</Grid>
								<Grid item xs={12} md={8}>
									<Grid
										container
										spacing={1}
										sx={{
											marginLeft: { xs: -1, md: 0 },
										}}
									>
										<Grid
											item
											xs={12}
											md={6}
											sx={{
												display: "flex",
												flexDirection: "column",
												justifyContent: "flex-start",
												gap: { xs: 1, md: "unset" },
												alignItems: destination
													? "flex-end"
													: "flex-start",
												mt: 0.5,
											}}
										>
											<TextField
												label="Connector Name"
												variant="outlined"
												size="small"
												value={
													searchText
														.charAt(0)
														.toUpperCase() +
													searchText.slice(1)
												}
												// value={result?.title}
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{
													sx: {
														height: 48,
														padding: 1,
													},
													inputProps: {
														sx: {
															padding: 0,
															height: "100%",
															display: "flex",
															alignItems:
																"center",
															fontSize:
																"0.875rem",
														},
													},
												}}
												sx={{
													width: "100%",
													marginBottom: 0.75,
													padding: 0,
												}}
											/>
											<TextField
												label="Connector Description"
												variant="outlined"
												size="small"
												value={result?.description}
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{
													sx: {
														height: 48,
														padding: 1,
													},
													inputProps: {
														sx: {
															padding: 0,
															height: "100%",
															display: "flex",
															alignItems:
																"center",
															fontSize:
																"0.875rem",
														},
													},
												}}
												sx={{
													width: "100%",
													marginBottom: 0.75,
													padding: 0,
												}}
											/>
										</Grid>
										<Grid
											item
											xs={12}
											md={6}
											sx={{
												display: "flex",
												flexDirection: "column",
												justifyContent: "flex-start",
												gap: { xs: 1, md: "unset" },
												alignItems: destination
													? "flex-end"
													: "flex-start",
												mt: 0.5,
											}}
										>
											<TextField
												label="Home URL"
												variant="outlined"
												size="small"
												value={result?.url}
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{
													sx: {
														height: 48,
														padding: 1,
													},
													inputProps: {
														sx: {
															padding: 0,
															height: "100%",
															display: "flex",
															alignItems:
																"center",
															fontSize:
																"0.875rem",
														},
													},
												}}
												sx={{
													width: "100%",
													marginBottom: 0.75,
													padding: 0,
												}}
											/>
											<TextField
												label="API Documentation URL"
												variant="outlined"
												size="small"
												value={
													result?.documentation_api_url
												}
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{
													sx: {
														height: 48,
														padding: 1,
													},
													inputProps: {
														sx: {
															padding: 0,
															height: "100%",
															display: "flex",
															alignItems:
																"center",
															fontSize:
																"0.875rem",
														},
													},
												}}
												sx={{
													width: "100%",
													marginBottom: 0.75,
													padding: 0,
												}}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Fragment>
					))}
				</Box>
			)}
			<Dialog
				open={showRequestConnectorModal}
				handleClose={handleClose}
				title={
					<Box
						sx={{
							height: 50,
							width: 50,
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-start",
							position: "relative",
							"&:hover .hoverButton": {
								display: "flex",
							},
							"&:hover .backdrop": {
								display: "block",
							},
						}}
					>
						{selectedConnector.logo_available ? (
							<Box
								sx={{
									height: 50,
									width: 50,
								}}
							>
								<CardMedia
									sx={{
										height: 50,
										width: 50,
										mx: {
											xs: "auto",
											md: "unset",
										},
										cursor: "pointer",
									}}
									image={selectedConnector?.logo_url}
								/>
							</Box>
						) : (
							<Box
								sx={{
									height: 50,
									width: 50,
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "center",
									border: "2px dashed lightgray",
									borderRadius: "10px",
									p: "4px",
								}}
							>
								<Typography
									sx={{
										fontWeight: 700,
										color: "lightgray",
									}}
								>
									No
								</Typography>
								<Typography
									sx={{
										fontWeight: 700,
										color: "lightgray",
									}}
								>
									Image
								</Typography>
							</Box>
						)}

						<Box
							className="backdrop"
							sx={{
								display: "none",
								position: "absolute",
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								backgroundColor: "#0c0c0cb0",
								borderRadius: "8px",
							}}
						/>

						<IconButton
							aria-label="delete"
							className="hoverButton"
							component="label"
							role={undefined}
							disableRipple
							sx={{
								display: "none", // initially hidden
								p: 0,
								flexDirection: "column",
								"& .MuiButton-startIcon": {
									m: 0,
								},
								position: "absolute",
								top: "calc(50% - 14px)", // vertically center the button
								left: "calc(50% - 13px)", // horizontally center the button
							}}
						>
							<CloudUploadIcon
								sx={{ color: "var(--data-con-blue)" }}
							/>
							<VisuallyHiddenInput type="file" />
						</IconButton>
						<span
							style={{
								marginLeft: "5px",
								lineHeight: "normal",
							}}
						>
							{selectedConnector.title.charAt(0).toUpperCase() +
								selectedConnector.title.slice(1)}
						</span>
					</Box>
				}
				centerAligned={false}
			>
				<Box>
					<TextField
						variant="outlined"
						label="Connector Name"
						fullWidth
						value={selectedConnector.title}
						onChange={(e) => {
							setSelectedConnector({
								...selectedConnector,
								title: e.target.value,
							});
						}}
					/>
					<TextField
						variant="outlined"
						label="Connector Description"
						fullWidth
						sx={{ mt: 2 }}
						value={selectedConnector.description}
						onChange={(e) => {
							setSelectedConnector({
								...selectedConnector,
								description: e.target.value,
							});
						}}
					/>
					<TextField
						variant="outlined"
						label="Home URL"
						fullWidth
						sx={{ mt: 2 }}
						value={selectedConnector.url}
						onChange={(e) => {
							setSelectedConnector({
								...selectedConnector,
								url: e.target.value,
							});
						}}
					/>
					<TextField
						variant="outlined"
						label="API Documentation URL"
						fullWidth
						sx={{ mt: 2 }}
						value={selectedConnector.documentation_api_url}
						onChange={(e) => {
							setSelectedConnector({
								...selectedConnector,
								documentation_api_url: e.target.value,
							});
						}}
					/>
					<Box
						sx={{
							mt: 2,
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Typography
							color="var(--data-con-blue)"
							sx={{ pr: 1, fontWeight: 700 }}
						>
							Please confirm the details and we'll automatically
							build the {selectedConnector.title} connector!
						</Typography>
						<LoadingButton
							onClick={handleSubmitConnectorRequest}
							loading={submitLoading}
							variant="contained"
						>
							BUILD
						</LoadingButton>
					</Box>
				</Box>
			</Dialog>

			{!submitLoading && newConnector && (
				<ConnectorModal
					showConnectorModal={showConnectorModal}
					handleClose={handleConnectorModalClose}
					title={newConnector.ConnectorNM}
					connector={newConnector}
					appImage={appImage()}
				/>
			)}
		</>
	);
};

export default ConnectorRequest;
