import { Fragment, useState, useEffect } from "react";
import {
	Box,
	Divider,
	Button,
	Grid,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import TestUserAccountsLoader from "./TestUserAccountsLoader";

const TestUserAccounts = () => {
	const [testUserAccountsInfo, setTestUserAccountsInfo] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		const timeoutId = setTimeout(() => {
			setTestUserAccountsInfo([]);
			setIsLoading(false);
		}, 500);
		return () => clearTimeout(timeoutId);
	}, []);

	const handleAddNewTestUser = () => {
		setTestUserAccountsInfo((prev) => [
			...prev,
			{
				verification_type: null,
				key_value: [{ key: "", value: "" }],
			},
		]);
	};

	const handleAddNewKeyValuePair = (userIndex) => {
		setTestUserAccountsInfo((prev) => {
			prev[userIndex].key_value.push({ key: "", value: "" });
			return [...prev];
		});
	};

	const changeAuthType = (event, index) => {
		setTestUserAccountsInfo((prev) => {
			prev[index].verification_type = event.target.value;
			return [...prev];
		});
	};

	const changeKey = (event, userIndex, keyIndex) => {
		setTestUserAccountsInfo((prev) => {
			prev[userIndex].key_value[keyIndex].key = event.target.value;
			return [...prev];
		});
	};

	const changeValue = (event, userIndex, valueIndex) => {
		setTestUserAccountsInfo((prev) => {
			prev[userIndex].key_value[valueIndex].value = event.target.value;
			return [...prev];
		});
	};

	const handleRemoveKeyValuePair = (userIndex, pairIndex) => {
		setTestUserAccountsInfo((prev) => {
			if (prev[userIndex].key_value.length > 1) {
				prev[userIndex].key_value.splice(pairIndex, 1);
			}
			return [...prev];
		});
	};

	const handleRemoveUser = (index) => {
		const newArr = [...testUserAccountsInfo];
		newArr.splice(index, 1);
		setTestUserAccountsInfo(newArr);
	};

	return (
		<>
			<Divider
				sx={{
					textTransform: "uppercase",
					fontSize: { xs: "14px", sm: "17px", md: "19.2px" },
				}}
			>
				Test User Accounts
			</Divider>

			{isLoading ? (
				<TestUserAccountsLoader />
			) : (
				<>
					<Box sx={{ textAlign: "center", py: 4 }}>
						<Button
							variant="contained"
							sx={{
								height: 40,
								minWidth: 150,
								backgroundColor: "var(--data-con-blue)",
							}}
							onClick={handleAddNewTestUser}
						>
							ADD TEST USER
						</Button>
					</Box>
					{testUserAccountsInfo?.map((user, i) => (
						<Box
							key={i}
							sx={{
								border: "1px solid lightgray",
								my: 2,
								borderRadius: "5px",
								position: "relative",
							}}
						>
							<Box
								sx={{
									position: "absolute",
									top: "-10px",
									left: "16px",
									backgroundColor: "white",
									padding: "0 8px",
									fontSize: "14px",
									fontWeight: "bold",
									color: "gray",
								}}
							>
								Test User #{i + 1}
							</Box>
							<IconButton
								disableRipple
								sx={{
									position: "absolute",
									right: 0,
								}}
								aria-label="delete"
								onClick={() => handleRemoveUser(i)}
							>
								<DeleteIcon />
							</IconButton>
							<Box sx={{ mt: 5, textAlign: "center" }}>
								<FormControl size="small">
									<InputLabel id="verification_type">
										Account Verification Type
									</InputLabel>
									<Select
										labelId="verification_type"
										value={user.verification_type}
										onChange={(e) => changeAuthType(e, i)}
										label="Account Verification Type"
										sx={{
											minWidth: { xs: 230, md: 250 },
										}}
									>
										<MenuItem value={""}>
											<em>None</em>
										</MenuItem>
										<MenuItem value={"oauth2"}>
											OAuth2
										</MenuItem>
										<MenuItem value={"oauth"}>
											OAuth
										</MenuItem>
										<MenuItem value={"username_password"}>
											Username / Password
										</MenuItem>
										<MenuItem value={"token"}>
											Token
										</MenuItem>
									</Select>
								</FormControl>
							</Box>
							{(user.verification_type === "oauth2" ||
								user.verification_type === "oauth") && (
								<Box sx={{ textAlign: "center", py: 2 }}>
									<Button
										variant="contained"
										sx={{
											minWidth: 150,
											backgroundColor:
												"var(--data-con-blue)",
										}}
										// onClick={handleAddNewTestUser}
									>
										Login
									</Button>
								</Box>
							)}
							{user.key_value.map((pair, index) => (
								<Grid
									key={index}
									container
									spacing={2}
									sx={{
										px: { xs: 1, md: 2 },
										my: 2,
										alignItems: "center",
									}}
								>
									<Grid item xs={10} md={5} sx={{ gap: 1 }}>
										<Grid
											container
											spacing={2}
											// sx={{ px: { md: 2 }, my: 2 }}
										>
											<Grid
												item
												xs={12}
												md={6}
												sx={{ gap: 1 }}
											>
												<TextField
													label="Authentication Key"
													variant="outlined"
													size="small"
													value={pair.key}
													onChange={(e) =>
														changeKey(e, i, index)
													}
													InputLabelProps={{
														shrink: true,
													}}
													InputProps={{
														sx: {
															height: 37,
															padding: 1,
														},
														inputProps: {
															sx: {
																padding: 0,
																height: "100%",
																display: "flex",
																alignItems:
																	"center",
																fontSize:
																	"0.875rem",
															},
														},
													}}
													sx={{
														width: "100%",

														padding: 0,
													}}
												/>
											</Grid>
											<Grid
												item
												xs={12}
												md={6}
												sx={{ gap: 1 }}
											>
												<TextField
													label="Authentication Value"
													variant="outlined"
													size="small"
													value={pair.value}
													onChange={(e) =>
														changeValue(e, i, index)
													}
													InputLabelProps={{
														shrink: true,
													}}
													InputProps={{
														sx: {
															height: 37,
															padding: 1,
														},
														inputProps: {
															sx: {
																padding: 0,
																height: "100%",
																display: "flex",
																alignItems:
																	"center",
																fontSize:
																	"0.875rem",
															},
														},
													}}
													sx={{
														width: "100%",

														padding: 0,
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={2} md={1} sx={{ gap: 1 }}>
										<Grid item xs={2} md={1}>
											{user.key_value.length > 1 && (
												<IconButton
													disableRipple
													sx={{ pl: 0 }}
													aria-label="delete"
													onClick={() =>
														handleRemoveKeyValuePair(
															i,
															index
														)
													}
												>
													<DeleteIcon />
												</IconButton>
											)}
										</Grid>
									</Grid>
								</Grid>
							))}
							<Button
								variant="contained"
								sx={{
									backgroundColor: "var(--data-con-blue)",
									m: 2,
								}}
								onClick={() => handleAddNewKeyValuePair(i)}
							>
								ADD KEY / VALUE
							</Button>
						</Box>
					))}
				</>
			)}
		</>
	);
};

export default TestUserAccounts;
