import {
	Box,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useEffect, useState } from "react";
import ApiGeneralInfoLoader from "./ApiGeneralInfoLoader";
import { useConnectorModalContext } from "../../connectors.context";
import { ConnectorAPIService } from "../../../../config/api-service";

const ApiGeneralInfo = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [activeTask, setActiveTask] = useState(null);
	const {
		selectedConnector,
		apiGeneralInfo,
		setConnectorInfo,
		setApiGeneralInfo,
		setActiveTaskList,
		activeTaskList,
		// activeTask,
	} = useConnectorModalContext();

	useEffect(() => {
		const task = activeTaskList?.find(
			(activeTask) => activeTask.prime_jira_task_id === 4
		);
		setActiveTask(task);
	}, [activeTaskList]);

	useEffect(() => {
		setIsLoading(true);
		ConnectorAPIService.getConnectorOrchestratorEvent(
			selectedConnector.ConnectorID,
			"METADATA"
		)
			.then((response) => {
				setApiGeneralInfo((prev) => ({
					...prev,
					version_changelog_url:
						response.dataList[0]?.version_change_log,
					github_url: response.dataList[0]?.api_github_url,
					release_date: response.dataList[0]?.release_date,
					support_contact_email: response.dataList[0]?.email,
					support_contact_phone: response.dataList[0]?.phone,
					terms_of_service_url:
						response.dataList[0]?.terms_of_service,
					privacy_policy_url: response.dataList[0]?.privacy_policy,
					supported_environments:
						response.dataList[0]?.supported_environments,
					supported_data_formats:
						response.dataList[0]?.supported_data_formats,
					last_update_date: response.dataList[0]?.last_updated,
					license_type: response.dataList[0]?.license,
					community_support_url:
						response.dataList[0]?.community_support,
					known_issues_url: response.dataList[0]?.known_issues,
				}));
				setConnectorInfo((prev) => ({
					...prev,
					home_url: response.dataList[0]?.home_url,
					category: response.dataList[0]?.category,
					industry: response.dataList[0]?.industry,
					tags: response.dataList[0]?.tags,
					type: response.dataList[0]?.type,
				}));
				setActiveTaskList(response.dataList[0]?.active_task_list);
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
			});
	}, [
		selectedConnector.ConnectorID,
		setApiGeneralInfo,
		setConnectorInfo,
		setActiveTaskList,
	]);

	const emailReg = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+.[a-zA-Z0-9+.]$/;

	return (
		<>
			<Divider
				sx={{
					textTransform: "uppercase",
					fontSize: { xs: "14px", sm: "17px", md: "19.2px" },
				}}
			>
				API GENERAL INFO
			</Divider>
			{isLoading ? (
				<ApiGeneralInfoLoader />
			) : (
				<>
					<Box sx={{ mt: 4, textAlign: "center" }}>
						<FormControl size="small">
							<InputLabel id="version">Version</InputLabel>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<Select
									disabled={activeTask}
									labelId="version"
									value={apiGeneralInfo?.version}
									onChange={(e) =>
										setApiGeneralInfo((prev) => ({
											...prev,
											version: e.target.value,
										}))
									}
									label="Version"
									sx={{
										minWidth: 200,
									}}
								>
									<MenuItem value={""}>
										<em>None</em>
									</MenuItem>
									<MenuItem value={"22.1"}>
										22.1 (Current)
									</MenuItem>
									<MenuItem value={"22.0"}>22.0</MenuItem>
									<MenuItem value={"21.9"}>21.9</MenuItem>
								</Select>
							</Tooltip>
						</FormControl>
					</Box>
					<Grid container spacing={2} sx={{ py: 4 }}>
						<Grid item xs={12} md={6} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="API Documentation URL"
									variant="outlined"
									size="small"
									value={
										apiGeneralInfo?.documentation_api_url
									}
									onChange={(e) =>
										setApiGeneralInfo((prev) => ({
											...prev,
											documentation_api_url:
												e.target.value,
										}))
									}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={6} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="API GitHub URL"
									variant="outlined"
									size="small"
									value={apiGeneralInfo?.github_url}
									onChange={(e) =>
										setApiGeneralInfo((prev) => ({
											...prev,
											github_url: e.target.value,
										}))
									}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",

										padding: 0,
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DatePicker
									disabled={activeTask}
									label="Release Date"
									views={["day"]}
									value={apiGeneralInfo?.release_date}
									onChange={(newValue) =>
										setApiGeneralInfo((prev) => ({
											...prev,
											release_date: newValue,
										}))
									}
									renderInput={(params) => (
										<Tooltip
											title={
												activeTask
													? `You can not update this field when the task: ${activeTask?.name} is in an active state`
													: ""
											}
											placement="top"
										>
											<TextField
												sx={{ minWidth: "100%" }}
												size="small"
												{...params}
												helperText={null}
											/>
										</Tooltip>
									)}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DatePicker
									disabled={activeTask}
									label="Last Update Date"
									views={["day"]}
									value={apiGeneralInfo?.last_update_date}
									onChange={(newValue) =>
										setApiGeneralInfo((prev) => ({
											...prev,
											last_update_date: newValue,
										}))
									}
									renderInput={(params) => (
										<Tooltip
											title={
												activeTask
													? `You can not update this field when the task: ${activeTask?.name} is in an active state`
													: ""
											}
											placement="top"
										>
											<TextField
												sx={{ minWidth: "100%" }}
												size="small"
												{...params}
												helperText={null}
											/>
										</Tooltip>
									)}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="Support Contact Email"
									variant="outlined"
									size="small"
									value={
										apiGeneralInfo?.support_contact_email
									}
									error={
										!emailReg.test(
											apiGeneralInfo?.support_contact_email
										) &&
										apiGeneralInfo?.support_contact_email !==
											""
									}
									onChange={(e) =>
										setApiGeneralInfo((prev) => ({
											...prev,
											support_contact_email:
												e.target.value,
										}))
									}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",

										padding: 0,
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="Support Contact Phone"
									variant="outlined"
									size="small"
									value={
										apiGeneralInfo?.support_contact_phone
									}
									onChange={(e) =>
										setApiGeneralInfo((prev) => ({
											...prev,
											support_contact_phone:
												e.target.value,
										}))
									}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",

										padding: 0,
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="Terms of Service URL"
									variant="outlined"
									size="small"
									value={apiGeneralInfo?.terms_of_service_url}
									onChange={(e) =>
										setApiGeneralInfo((prev) => ({
											...prev,
											terms_of_service_url:
												e.target.value,
										}))
									}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",

										padding: 0,
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="Privacy Policy URL"
									variant="outlined"
									size="small"
									value={apiGeneralInfo?.privacy_policy_url}
									onChange={(e) =>
										setApiGeneralInfo((prev) => ({
											...prev,
											privacy_policy_url: e.target.value,
										}))
									}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",

										padding: 0,
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<FormControl
								size="small"
								sx={{
									minWidth: "100%",
								}}
							>
								<InputLabel
									id="Supported-Environments"
									sx={{
										fontSize: "0.875rem",
										textAlign: "left",
									}}
								>
									Supported Environments
								</InputLabel>
								<Tooltip
									title={
										activeTask
											? `You can not update this field when the task: ${activeTask?.name} is in an active state`
											: ""
									}
									placement="top"
								>
									<Select
										disabled={activeTask}
										labelId="Supported-Environments"
										value={
											apiGeneralInfo?.supported_environments
										}
										onChange={(e) =>
											setApiGeneralInfo((prev) => ({
												...prev,
												supported_environments:
													e.target.value,
											}))
										}
										label="Supported Environments"
										sx={{
											fontSize: "0.875rem",
											textAlign: "left",
										}}
									>
										<MenuItem value={""}>
											<em>None</em>
										</MenuItem>
										<MenuItem value={"Development"}>
											Development
										</MenuItem>
										<MenuItem value={"Production"}>
											Production
										</MenuItem>
									</Select>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<FormControl
								size="small"
								sx={{
									minWidth: "100%",
								}}
							>
								<InputLabel
									id="Supported-Data-Formats"
									sx={{
										fontSize: "0.875rem",
										textAlign: "left",
									}}
								>
									Supported Data Formats
								</InputLabel>
								<Tooltip
									title={
										activeTask
											? `You can not update this field when the task: ${activeTask?.name} is in an active state`
											: ""
									}
									placement="top"
								>
									<Select
										disabled={activeTask}
										labelId="Supported-Data-Formats"
										value={
											apiGeneralInfo?.supported_data_formats
										}
										onChange={(e) =>
											setApiGeneralInfo((prev) => ({
												...prev,
												supported_data_formats:
													e.target.value,
											}))
										}
										label="Supported Data Formats"
										sx={{
											fontSize: "0.875rem",
											textAlign: "left",
										}}
									>
										<MenuItem value={""}>
											<em>None</em>
										</MenuItem>
										<MenuItem value={"Development"}>
											Development
										</MenuItem>
										<MenuItem value={"Production"}>
											Production
										</MenuItem>
									</Select>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="License Type"
									variant="outlined"
									size="small"
									value={apiGeneralInfo?.license_type}
									onChange={(e) =>
										setApiGeneralInfo((prev) => ({
											...prev,
											license_type: e.target.value,
										}))
									}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",

										padding: 0,
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="Version Change Log URL"
									variant="outlined"
									size="small"
									value={
										apiGeneralInfo?.version_changelog_url
									}
									onChange={(e) =>
										setApiGeneralInfo((prev) => ({
											...prev,
											version_changelog_url:
												e.target.value,
										}))
									}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",

										padding: 0,
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="Community Support URL"
									variant="outlined"
									size="small"
									value={
										apiGeneralInfo?.community_support_url
									}
									onChange={(e) =>
										setApiGeneralInfo((prev) => ({
											...prev,
											community_support_url:
												e.target.value,
										}))
									}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",

										padding: 0,
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="Known Issues URL"
									variant="outlined"
									size="small"
									value={apiGeneralInfo?.known_issues_url}
									onChange={(e) =>
										setApiGeneralInfo((prev) => ({
											...prev,
											known_issues_url: e.target.value,
										}))
									}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",

										padding: 0,
									}}
								/>
							</Tooltip>
						</Grid>
					</Grid>
				</>
			)}
		</>
	);
};

export default ApiGeneralInfo;
