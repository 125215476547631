import { Skeleton } from "@mui/material";
import React from "react";

const ObjectLoader = () => {
	return (
		<>
			<Skeleton
				variant="rounded"
				height={40}
				width="100%"
				sx={{ borderRadius: "5px", mb: 1 }}
			/>
			<Skeleton
				variant="rounded"
				height={40}
				width="100%"
				sx={{ borderRadius: "5px", mb: 1 }}
			/>
			<Skeleton
				variant="rounded"
				height={40}
				width="100%"
				sx={{ borderRadius: "5px", mb: 1 }}
			/>
			<Skeleton
				variant="rounded"
				height={40}
				width="100%"
				sx={{ borderRadius: "5px" }}
			/>
		</>
	);
};

export default ObjectLoader;
