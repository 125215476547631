import { Grid, Skeleton } from "@mui/material";

const ConnectorInfoLoader = () => {
	return (
		<>
			<Grid
				container
				spacing={2}
				sx={{
					py: 4,
					display: "flex",
					flexDirection: {
						xs: "column",
						md: "row",
					},
					justifyContent: "flex-start",
					textAlign: "center",
				}}
			>
				<Grid
					item
					xs={12}
					md={2}
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: {
							xs: "center",
							md: "flex-start",
						},
					}}
				>
					<Skeleton height={150} width={140} />
				</Grid>
				<Grid item xs={12} md={10}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={5}>
							<Skeleton
								variant="rounded"
								height={40}
								width="100%"
								sx={{ borderRadius: "5px" }}
							/>
						</Grid>
						<Grid item xs={12} md={5}>
							<Skeleton
								variant="rounded"
								height={40}
								width="100%"
								sx={{ borderRadius: "5px" }}
							/>
						</Grid>
						<Grid item xs={12} md={2}>
							<Skeleton
								variant="rounded"
								height={40}
								width="100%"
								sx={{ borderRadius: "5px" }}
							/>
						</Grid>
						<Grid item xs={12} md={10}>
							<Skeleton
								variant="rounded"
								height={40}
								width="100%"
								sx={{ borderRadius: "5px" }}
							/>
						</Grid>
						<Grid item xs={12} md={2}>
							<Skeleton
								variant="rounded"
								height={40}
								width="100%"
								sx={{ borderRadius: "5px" }}
							/>
						</Grid>
						<Grid item xs={12} md={10}>
							<Skeleton
								variant="rounded"
								height={40}
								width="100%"
								sx={{ borderRadius: "5px" }}
							/>
						</Grid>
						<Grid item xs={12} md={2}>
							<Skeleton
								variant="rounded"
								height={40}
								width="100%"
								sx={{ borderRadius: "5px" }}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default ConnectorInfoLoader;
