import { useEffect, useState } from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Divider,
	Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ConnectorAPIService } from "../../../../config/api-service";
import { useConnectorModalContext } from "../../connectors.context";
import ObjectLoader from "./ObjectLoader";
import Connections from "./Connections";
import ConnectionsLoader from "./Connections/ConnectionsLoader";

const ApiEndPoints = () => {
	const [expanded, setExpanded] = useState(false);
	const [objects, setObjects] = useState([]);
	const [fields, setFields] = useState({});
	const [isObjectsLoading, setIsObjectsLoading] = useState(false);
	const [isFieldsLoading, setIsFieldsLoading] = useState(false);

	const { selectedConnector } = useConnectorModalContext();

	useEffect(() => {
		if (selectedConnector.ConnectorID) {
			setIsObjectsLoading(true);
			ConnectorAPIService.getConnectorObject(
				selectedConnector.ConnectorID
			)
				.then((res) => {
					setObjects(res.objectList);
					setIsObjectsLoading(false);
				})
				.catch(() => {
					setIsObjectsLoading(false);
				});
		}
	}, [selectedConnector.ConnectorID]);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);

		if (isExpanded) {
			setIsFieldsLoading(true);
			ConnectorAPIService.getConnectorField(panel)
				.then((res) => {
					setFields((prevFields) => ({
						...prevFields,
						[panel]: res.fieldList,
					}));
					setIsFieldsLoading(false);
				})
				.catch(() => {
					setIsFieldsLoading(false);
				});
		}
	};

	return (
		<>
			<Divider
				sx={{
					textTransform: "uppercase",
					fontSize: { xs: "14px", sm: "17px", md: "19.2px" },
				}}
			>
				API ENDPOINTS
			</Divider>
			<Box sx={{ textAlign: "center", py: 4 }}>
				<Button
					variant="contained"
					sx={{
						height: 40,
						minWidth: 150,
						backgroundColor: "var(--data-con-blue)",
					}}
				>
					ADD OBJECT
				</Button>
			</Box>
			{isObjectsLoading ? (
				<ObjectLoader />
			) : !objects.length ? (
				<Typography
					sx={{
						color: "red",
						textAlign: "center",
						mt: 1,
					}}
					variant="body"
					component="p"
				>
					No Objects
				</Typography>
			) : (
				<Box sx={{ border: "1px solid lightgray" }}>
					{objects.map((object) => (
						<Accordion
							key={object.connector_object_id}
							expanded={expanded === object.connector_object_id}
							onChange={handleChange(object.connector_object_id)}
							sx={{
								boxShadow: "none",
								backgroundColor: "#e1f8ff",
								"&.Mui-expanded": {
									backgroundColor: "transparent",
								},
							}}
						>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography>{object.object_nm}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								{isFieldsLoading &&
								expanded === object.connector_object_id ? (
									<ConnectionsLoader />
								) : !fields[object.connector_object_id]
										?.length ? (
									<Typography
										sx={{
											color: "red",
											textAlign: "center",
											mt: 1,
										}}
										variant="body"
										component="p"
									>
										No Fields
									</Typography>
								) : (
									<Connections
										fields={
											fields[object.connector_object_id]
										}
									/>
								)}
							</AccordionDetails>
						</Accordion>
					))}
				</Box>
			)}
		</>
	);
};

export default ApiEndPoints;
