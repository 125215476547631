import { Grid, Skeleton } from "@mui/material";
import React from "react";

const DeveloperAccountSetupLoader = () => {
	return (
		<>
			<Grid container spacing={2} sx={{ py: 4 }}>
				<Grid item xs={12} md={6}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={4.9}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={1.1}
					sx={{ gap: 1, textAlign: { xs: "center", md: "unset" } }}
				>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Skeleton
						variant="rounded"
						height={120}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Skeleton
						variant="rounded"
						height={120}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default DeveloperAccountSetupLoader;
