import React, { useEffect, useState } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
	Grid,
	Select,
	MenuItem,
	Button,
	Box,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography,
} from "@mui/material";
import TaskLoader from "./TaskLoader";
import { JiraAPIService } from "../../../config/api-service";
import { useConnectorModalContext } from "../connectors.context";

const statusMapper = {
	Completed: "#00800038",
	QA: "#4766ff30",
	Roadblocked: "#ff000033",
	Backlog: "#80808033",
	Active: "#0000ff33",
	Pending: "#ffa50033",
	"ON HOLD": "#ffff0033",
	"Needs Intervention": "#ff69b433",
	Deployed: "#00ff0033",
};

function getStatusColor(status) {
	return statusMapper[status] || "#00000030";
}

const columns = [
	{
		id: "jira_key_id",
		label: "TASK",
		align: "left",
		customFormat: (value) => {
			return (
				<a
					href={value.taskUrl}
					target="_blank"
					rel="noreferrer"
					style={{ textDecoration: "none" }}
				>
					<span style={{ display: "flex", alignItems: "center" }}>
						<CheckBoxIcon sx={{ color: "var(--data-con-blue)" }} />
						<span style={{ paddingLeft: "5px" }}>{value.name}</span>
					</span>
				</a>
			);
		},
	},
	{
		id: "description",
		label: "DESCRIPTION",
		align: "left",
	},
	{
		id: "status_name",
		label: "STATUS",
		align: "center",
		format: (value) => {
			return (
				<span
					style={{
						backgroundColor: getStatusColor(value),
						fontSize: "11px",
						padding: "3px 8px",
						width: "100%",
						margin: 0,
						display: "inline-block",
						color: getStatusColor(value).slice(0, -2),
					}}
				>
					{value?.toUpperCase()}
				</span>
			);
		},
	},
	{
		id: "assignee_email",
		label: "ASSIGNEE",
		align: "left",
	},
	{
		id: "percentage",
		label: "% COMPLETE",
		align: "left",
	},
];

const TasksTab = ({ tasks, isTasksLoading }) => {
	const [task, setTask] = useState(null);
	const [taskOptions, setTaskOptions] = useState([]);
	const [status, setStatus] = useState(null);
	const [statusOptions, setStatusOptions] = useState([]);
	const [assignee, setAssignee] = useState(null);
	const [assigneeOptions, setAssigneeOptions] = useState([]);
	const [taskData, setTaskData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [queryParams, setQueryParams] = useState({});
	// let queryParams = {};

	const { selectedConnector } = useConnectorModalContext();

	useEffect(() => {
		let isMounted = true;
		const fetchAllData = async () => {
			setIsLoading(true);
			try {
				const [statusResponse, usersResponse] = await Promise.all([
					JiraAPIService.getStatus(),
					JiraAPIService.getUsers(),
				]);

				if (isMounted) {
					if (statusResponse) {
						setStatusOptions(statusResponse);
					}

					if (usersResponse) {
						setAssigneeOptions(usersResponse);
					}

					setIsLoading(false);
				}
			} catch (error) {
				if (isMounted) {
					setIsLoading(false);
				}
			}
		};

		fetchAllData();

		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		if (Object.keys(queryParams).length === 0) {
			setTaskOptions(tasks);
			setTaskData(tasks);
		}
	}, [tasks, queryParams]);

	const handleClearFilter = async () => {
		setTask(null);
		setStatus(null);
		setAssignee(null);
		setQueryParams({});
		const response = await JiraAPIService.getTasks(
			selectedConnector.ConnectorID
		);
		if (response) {
			setTaskData(response);
			setTaskOptions(response);
		}
	};

	const onChangeFilter = async (target) => {
		//? Handle task filter
		if (target.name === "task") {
			setTask(target.value);
		}
		//? Handle status filter
		if (target.name === "status") {
			setStatus(target.value);
		}
		//? Handle assignee filter
		if (target.name === "assignee") {
			setAssignee(target.value);
		}
	};

	useEffect(() => {
		if (task) {
			setQueryParams((prev) => ({ ...prev, TaskID: task }));
		} else {
			setQueryParams((prev) => {
				if (prev.TaskID) {
					delete prev.TaskID;
				}
				return { ...prev };
			});
		}
		if (status) {
			setQueryParams((prev) => ({ ...prev, StatusID: status }));
		} else {
			setQueryParams((prev) => {
				if (prev.StatusID) {
					delete prev.StatusID;
				}
				return { ...prev };
			});
		}
		if (assignee) {
			setQueryParams((prev) => ({ ...prev, AssigneeEmail: assignee }));
		} else {
			setQueryParams((prev) => {
				if (prev.AssigneeEmail) {
					delete prev.AssigneeEmail;
				}
				return { ...prev };
			});
		}
	}, [assignee, status, task]);

	useEffect(() => {
		let isMounted = true;

		const getTasks = async () => {
			setIsLoading(true);
			try {
				const response = await JiraAPIService.getTasks(
					selectedConnector.ConnectorID,
					queryParams
				);
				if (isMounted) {
					if (response) {
						setTaskData(response);
					}
					setIsLoading(false);
				}
			} catch (error) {
				if (isMounted) {
					setIsLoading(false);
				}
			}
		};

		getTasks();

		return () => {
			isMounted = false;
		};
	}, [queryParams, selectedConnector.ConnectorID]);

	return (
		<Box>
			<Grid
				container
				spacing={1}
				sx={{ justifyContent: { xs: "center", md: "space-between" } }}
			>
				<Grid item xs={12} sm={9} md={6}>
					<Select
						size="small"
						value={task}
						name="task"
						onChange={(e) => onChangeFilter(e.target)}
						displayEmpty
						sx={{ width: "100%" }}
					>
						<MenuItem value={null}>Task</MenuItem>
						{taskOptions.map((task) => (
							<MenuItem key={task.task_id} value={task.task_id}>
								{task.jira_key_id} : {task.task_name}
							</MenuItem>
						))}
					</Select>
				</Grid>
				<Grid item xs={12} sm={3} md={1.4}>
					<Select
						size="small"
						value={status}
						name="status"
						onChange={(e) => onChangeFilter(e.target)}
						displayEmpty
						sx={{ width: "100%" }}
					>
						<MenuItem value={null}>Status</MenuItem>
						{statusOptions.map((status) => (
							<MenuItem
								key={status.status_id}
								value={status.status_id}
							>
								{status.name}
							</MenuItem>
						))}
					</Select>
				</Grid>
				<Grid item xs={12} sm={7} md={2.9}>
					<Select
						size="small"
						value={assignee}
						name="assignee"
						onChange={(e) => onChangeFilter(e.target)}
						displayEmpty
						sx={{ width: "100%" }}
					>
						<MenuItem value={null}>Assignee</MenuItem>
						{assigneeOptions.map((assignee) => (
							<MenuItem
								key={assignee.jira_user_id}
								value={assignee.email}
							>
								{assignee.user_name} ({assignee.email})
							</MenuItem>
						))}
					</Select>
				</Grid>

				<Grid item xs={12} sm={5} md={1.7}>
					<Button
						variant="outlined"
						sx={{ height: 40, width: "100%" }}
						onClick={handleClearFilter}
					>
						CLEAR FILTERS
					</Button>
				</Grid>
			</Grid>

			<TableContainer sx={{ maxHeight: 300, mt: 1 }}>
				<Table stickyHeader dense table size="small">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									sx={{
										backgroundColor: "var(--data-con-blue)",
										color: "#fff",
										fontWeight: 700,
										fontSize: { md: "14px" },
									}}
									key={column.id}
									align={column.align}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading || isTasksLoading ? (
							<TaskLoader />
						) : (
							taskData.map((row) => {
								return (
									<TableRow
										hover
										role="checkbox"
										tabIndex={-1}
										key={row.task_id}
										sx={{
											"& .MuiTableCell-root": {
												maxWidth: "unset",
												borderBottom: "none",
											},
										}}
									>
										{columns.map((column) => {
											const value = row[column.id];

											const taskName = row.task_name;
											const taskUrl = row.task_url;

											return (
												<TableCell
													key={column.id}
													align={column.align}
													style={{
														whiteSpace:
															column.id ===
																"description" &&
															"unset",
													}}
												>
													{column.format
														? column.format(value)
														: column.customFormat
														? column.customFormat({
																name:
																	value +
																	": " +
																	taskName,
																taskUrl,
														  })
														: value}
												</TableCell>
											);
										})}
									</TableRow>
								);
							})
						)}
					</TableBody>
				</Table>
			</TableContainer>
			{!taskData.length && !isLoading && (
				<Typography
					sx={{ color: "red", textAlign: "center", mt: 2 }}
					variant="body"
					component="p"
				>
					No Tasks
				</Typography>
			)}
		</Box>
	);
};

export default TasksTab;
