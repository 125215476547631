import { Button, Divider, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import DeveloperAccountSetupLoader from "./DeveloperAccountSetupLoader";

const DeveloperAccountSetup = () => {
	const [developerAccountInfo, setDeveloperAccountInfo] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		const timeoutId = setTimeout(() => {
			setDeveloperAccountInfo({
				developer_portal_url: "",
				jira_task: "",
				sign_up_requirements: "",
				account_creation_steps: "",
			});
			setIsLoading(false);
		}, 500);
		return () => clearTimeout(timeoutId);
	}, []);
	return (
		<>
			<Divider
				sx={{
					textTransform: "uppercase",
					fontSize: { xs: "14px", sm: "17px", md: "19.2px" },
				}}
			>
				Developer Account Setup
			</Divider>
			{isLoading ? (
				<DeveloperAccountSetupLoader />
			) : (
				<Grid container spacing={2} sx={{ py: 4 }}>
					<Grid item xs={12} md={6} sx={{ gap: 1 }}>
						<TextField
							label="Developer Portal URL"
							variant="outlined"
							size="small"
							value={developerAccountInfo?.developer_portal_url}
							onChange={(e) =>
								setDeveloperAccountInfo((prev) => ({
									...prev,
									developer_portal_url: e.target.value,
								}))
							}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								sx: {
									height: 37,
									padding: 1,
								},
								inputProps: {
									sx: {
										padding: 0,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",
								padding: 0,
							}}
						/>
					</Grid>
					<Grid item xs={12} md={4.9} sx={{ gap: 1 }}>
						<TextField
							label="Jira Task"
							variant="outlined"
							size="small"
							value={developerAccountInfo?.jira_task}
							onChange={(e) =>
								setDeveloperAccountInfo((prev) => ({
									...prev,
									jira_task: e.target.value,
								}))
							}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								sx: {
									height: 37,
									padding: 1,
								},
								inputProps: {
									sx: {
										padding: 0,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",

								padding: 0,
							}}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={1.1}
						sx={{
							gap: 1,
							textAlign: { xs: "center", md: "unset" },
						}}
					>
						<Button
							variant="contained"
							sx={{ backgroundColor: "var(--data-con-blue)" }}
							// onClick={handleClearFilter}
						>
							CREATE
						</Button>
					</Grid>
					<Grid item xs={12} md={6} sx={{ gap: 1 }}>
						<TextField
							label="Sign-Up Requirements"
							variant="outlined"
							multiline
							rows={4}
							value={developerAccountInfo?.sign_up_requirements}
							onChange={(e) =>
								setDeveloperAccountInfo((prev) => ({
									...prev,
									sign_up_requirements: e.target.value,
								}))
							}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								sx: {
									padding: 1,
								},
								inputProps: {
									sx: {
										padding: 0,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",
								padding: 0,
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6} sx={{ gap: 1 }}>
						<TextField
							label="Account Creation Steps"
							variant="outlined"
							multiline
							rows={4}
							value={developerAccountInfo?.account_creation_steps}
							onChange={(e) =>
								setDeveloperAccountInfo((prev) => ({
									...prev,
									account_creation_steps: e.target.value,
								}))
							}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								sx: {
									padding: 1,
								},
								inputProps: {
									sx: {
										padding: 0,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",
								padding: 0,
							}}
						/>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default DeveloperAccountSetup;
