import { Grid, Skeleton } from "@mui/material";

const ApplicationDetailsLoader = () => {
	return (
		<>
			<Grid container spacing={2} sx={{ py: 4 }}>
				<Grid item xs={12} md={6}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<Skeleton
								variant="rounded"
								height={40}
								width="100%"
								sx={{ borderRadius: "5px" }}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Skeleton
								variant="rounded"
								height={40}
								width="100%"
								sx={{ borderRadius: "5px" }}
							/>
						</Grid>
						<Grid item xs={11.2}>
							<Skeleton
								variant="rounded"
								height={40}
								width="100%"
								sx={{ borderRadius: "5px" }}
							/>
						</Grid>
						<Grid item xs={0.8} sx={{ textAlign: "right" }}>
							<Skeleton
								variant="rounded"
								height={40}
								width="100%"
								sx={{ borderRadius: "5px" }}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							sx={{ textAlign: { xs: "center", md: "unset" } }}
						>
							<Skeleton
								variant="rounded"
								height={40}
								width="90px"
								sx={{ borderRadius: "5px" }}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} md={6}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<Skeleton
								variant="rounded"
								height={40}
								width="100%"
								sx={{ borderRadius: "5px" }}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Skeleton
								variant="rounded"
								height={40}
								width="100%"
								sx={{ borderRadius: "5px" }}
							/>
						</Grid>
						<Grid item xs={11.2}>
							<Skeleton
								variant="rounded"
								height={40}
								width="100%"
								sx={{ borderRadius: "5px" }}
							/>
						</Grid>
						<Grid item xs={0.8} sx={{ textAlign: "right" }}>
							<Skeleton
								variant="rounded"
								height={40}
								width="100%"
								sx={{ borderRadius: "5px" }}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							sx={{ textAlign: { xs: "center", md: "unset" } }}
						>
							<Skeleton
								variant="rounded"
								height={40}
								width="90px"
								sx={{ borderRadius: "5px" }}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid container spacing={2} sx={{ pb: 4 }}>
				<Grid item xs={12}>
					<Skeleton
						variant="rounded"
						height={120}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12}>
					<Skeleton
						variant="rounded"
						height={120}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12}>
					<Skeleton
						variant="rounded"
						height={120}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default ApplicationDetailsLoader;
