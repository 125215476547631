import { Box, Grid, Skeleton } from "@mui/material";
import React from "react";

const ApiGeneralInfoLoader = () => {
	return (
		<>
			<Box sx={{ mt: 4, textAlign: "center" }}>
				<Skeleton
					sx={{ mx: "auto", borderRadius: "5px" }}
					variant="rounded"
					height={40}
					width={200}
				/>
			</Box>
			<Grid container spacing={2} sx={{ py: 4 }}>
				<Grid item xs={12} md={6} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={6} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default ApiGeneralInfoLoader;
