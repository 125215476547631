import { useEffect, useState } from "react";
import {
	Box,
	Button,
	CardMedia,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { styled } from "@mui/material/styles";
import ConnectorInfoLoader from "./ConnectorInfoLoader";
import { useConnectorModalContext } from "../../connectors.context";
import { ConnectorAPIService } from "../../../../config/api-service";

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

const ConnectorInfo = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [activeTask, setActiveTask] = useState(null);

	const {
		selectedConnector,
		connectorInfo,
		setConnectorInfo,
		appImage,
		setApiGeneralInfo,
		activeTaskList,
	} = useConnectorModalContext();

	useEffect(() => {
		const task = activeTaskList?.find(
			(activeTask) => activeTask.prime_jira_task_id === 6
		);
		setActiveTask(task);
	}, [activeTaskList]);

	useEffect(() => {
		setIsLoading(true);
		ConnectorAPIService.getConnectorById(selectedConnector.ConnectorID)
			.then((response) => {
				setConnectorInfo((prev) => ({
					...prev,
					connector_name: response.connector.ConnectorNM,
					description: response.connector.ConnectorDSC,
				}));
				setApiGeneralInfo((prev) => ({
					...prev,
					documentation_api_url: response.connector.DocumentationURL,
				}));
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
			});
	}, [selectedConnector.ConnectorID, setApiGeneralInfo, setConnectorInfo]);

	return (
		<>
			<Divider
				sx={{
					textTransform: "uppercase",
					fontSize: { xs: "14px", sm: "17px", md: "19.2px" },
				}}
			>
				CONNECTOR INFO
			</Divider>
			{isLoading ? (
				<ConnectorInfoLoader />
			) : (
				<Grid
					container
					spacing={2}
					sx={{
						py: 4,
						display: "flex",
						flexDirection: {
							xs: "column",
							md: "row",
						},
						justifyContent: "flex-start",
						textAlign: "center",
					}}
				>
					<Grid
						item
						xs={12}
						md={2}
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: {
								xs: "center",
								md: "flex-start",
							},
						}}
					>
						<Tooltip
							title={
								activeTask
									? `You can not update this field when the task: ${activeTask?.name} is in an active state`
									: ""
							}
							placement="top"
						>
							<Box
								sx={{
									height: 85,
									width: 130,
									mx: { xs: "auto", md: "unset" },
									border: "2px dashed lightgray",
									borderRadius: "10px",
									p: "4px",
									position: "relative",
									overflow: "hidden", // ensure the content doesn't spill outside the box
									"&:hover .hoverButton": {
										display: "flex", // or "block" depending on your needs
									},
									"&:hover .backdrop": {
										display: "block",
									},
								}}
							>
								<CardMedia
									// onClick={handleOpen}
									alt="app"
									image={appImage}
									sx={{
										height: 85,
										width: 130,
										borderRadius: "8px",
									}}
								/>

								<Box
									className="backdrop"
									sx={{
										display: "none",
										position: "absolute",
										top: 0,
										left: 0,
										right: 0,
										bottom: 0,
										backgroundColor: "rgba(0, 0, 0, 0.5)",
										borderRadius: "8px",
									}}
								/>

								<Button
									disabled={activeTask}
									className="hoverButton"
									component="label"
									role={undefined}
									variant="contained"
									tabIndex={-1}
									startIcon={<CloudUploadIcon />}
									sx={{
										display: "none", // initially hidden
										flexDirection: "column",
										"& .MuiButton-startIcon": {
											m: 0,
										},
										":hover": {
											backgroundColor: "#00bfff",
											display: "flex",
											flexDirection: "column",
										},
										position: "absolute",
										top: "calc(50% - 24px)", // vertically center the button
										left: "calc(50% - 47px)", // horizontally center the button
									}}
								>
									<VisuallyHiddenInput type="file" />
									<Typography
										component={"span"}
										sx={{ fontSize: 9 }}
									>
										Browse File
									</Typography>
								</Button>
							</Box>
						</Tooltip>
					</Grid>
					<Grid item xs={12} md={10} sx={{ gap: 1 }}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={5} sx={{ gap: 1 }}>
								<Tooltip
									title={
										activeTask
											? `You can not update this field when the task: ${activeTask?.name} is in an active state`
											: ""
									}
									placement="top"
								>
									<TextField
										disabled={activeTask}
										label="Connector Name"
										variant="outlined"
										size="small"
										value={connectorInfo?.connector_name}
										onChange={(e) =>
											setConnectorInfo((prev) => ({
												...prev,
												connector_name: e.target.value,
											}))
										}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											sx: {
												height: 37,
												padding: 1,
											},
											inputProps: {
												sx: {
													padding: 0,
													height: "100%",
													display: "flex",
													alignItems: "center",
													fontSize: "0.875rem",
												},
											},
										}}
										sx={{
											width: "100%",
											marginBottom: 0.75,
											padding: 0,
										}}
									/>
								</Tooltip>
							</Grid>
							<Grid item xs={12} md={5} sx={{ gap: 1 }}>
								<Tooltip
									title={
										activeTask
											? `You can not update this field when the task: ${activeTask?.name} is in an active state`
											: ""
									}
									placement="top"
								>
									<TextField
										disabled={activeTask}
										label="Home URL"
										variant="outlined"
										size="small"
										value={connectorInfo?.home_url}
										onChange={(e) =>
											setConnectorInfo((prev) => ({
												...prev,
												c: e.target.value,
											}))
										}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											sx: {
												height: 37,
												padding: 1,
											},
											inputProps: {
												sx: {
													padding: 0,
													height: "100%",
													display: "flex",
													alignItems: "center",
													fontSize: "0.875rem",
												},
											},
										}}
										sx={{
											width: "100%",
											marginBottom: 0.75,
											padding: 0,
										}}
									/>
								</Tooltip>
							</Grid>
							<Grid item xs={12} md={2} sx={{ gap: 1 }}>
								<FormControl
									size="small"
									sx={{
										minWidth: "100%",
									}}
								>
									<InputLabel
										id="Category"
										sx={{
											fontSize: "0.875rem",
											textAlign: "left",
										}}
									>
										Category
									</InputLabel>
									<Tooltip
										title={
											activeTask
												? `You can not update this field when the task: ${activeTask?.name} is in an active state`
												: ""
										}
										placement="top"
									>
										<Select
											disabled={activeTask}
											labelId="Category"
											value={
												connectorInfo?.category || ""
											}
											onChange={(e) =>
												setConnectorInfo((prev) => ({
													...prev,
													category: e.target.value,
												}))
											}
											label="Category"
											sx={{
												fontSize: "0.875rem",
												textAlign: "left",
												maxWidth: { sm: "142px" },
											}}
										>
											<MenuItem value={""}>
												<em>None</em>
											</MenuItem>
											<MenuItem value={"DATABASE"}>
												DATABASE
											</MenuItem>
											<MenuItem value={"REST API"}>
												REST API
											</MenuItem>
										</Select>
									</Tooltip>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={10} sx={{ gap: 1 }}>
								<Tooltip
									title={
										activeTask
											? `You can not update this field when the task: ${activeTask?.name} is in an active state`
											: ""
									}
									placement="top"
								>
									<TextField
										disabled={activeTask}
										label="Description"
										variant="outlined"
										size="small"
										value={connectorInfo?.description}
										onChange={(e) =>
											setConnectorInfo((prev) => ({
												...prev,
												description: e.target.value,
											}))
										}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											sx: {
												height: 37,
												padding: 1,
											},
											inputProps: {
												sx: {
													padding: 0,
													height: "100%",
													display: "flex",
													alignItems: "center",
													fontSize: "0.875rem",
												},
											},
										}}
										sx={{
											width: "100%",
											marginBottom: 0.75,
											padding: 0,
										}}
									/>
								</Tooltip>
							</Grid>
							<Grid item xs={12} md={2} sx={{ gap: 1 }}>
								<FormControl
									size="small"
									sx={{
										minWidth: "100%",
									}}
								>
									<InputLabel
										id="Industry"
										sx={{
											fontSize: "0.875rem",
											textAlign: "left",
										}}
									>
										Industry
									</InputLabel>
									<Tooltip
										title={
											activeTask
												? `You can not update this field when the task: ${activeTask?.name} is in an active state`
												: ""
										}
										placement="top"
									>
										<Select
											disabled={activeTask}
											size="small"
											value={
												connectorInfo?.industry || ""
											}
											onChange={(e) =>
												setConnectorInfo((prev) => ({
													...prev,
													industry: e.target.value,
												}))
											}
											label="Industry"
											labelId="Industry"
											sx={{
												fontSize: "0.875rem",
												textAlign: "left",
												maxWidth: { sm: "142px" },
											}}
										>
											<MenuItem value={""}>
												<em>None</em>
											</MenuItem>
											{industriesOption.map((option) => (
												<MenuItem
													key={option}
													value={option}
												>
													{option}
												</MenuItem>
											))}
										</Select>
									</Tooltip>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={10} sx={{ gap: 1 }}>
								<Tooltip
									title={
										activeTask
											? `You can not update this field when the task: ${activeTask?.name} is in an active state`
											: ""
									}
									placement="top"
								>
									<TextField
										disabled={activeTask}
										label="Tags"
										variant="outlined"
										size="small"
										value={connectorInfo?.tags}
										onChange={(e) =>
											setConnectorInfo((prev) => ({
												...prev,
												tags: e.target.value,
											}))
										}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											sx: {
												height: 37,
												padding: 1,
											},
											inputProps: {
												sx: {
													padding: 0,
													height: "100%",
													display: "flex",
													alignItems: "center",
													fontSize: "0.875rem",
												},
											},
										}}
										sx={{
											width: "100%",
											marginBottom: 0.75,
											padding: 0,
										}}
									/>
								</Tooltip>
							</Grid>
							<Grid item xs={12} md={2} sx={{ gap: 1 }}>
								<FormControl
									size="small"
									sx={{
										minWidth: "100%",
									}}
								>
									<InputLabel
										id="Type"
										sx={{
											fontSize: "0.875rem",
											textAlign: "left",
										}}
									>
										Type
									</InputLabel>
									<Tooltip
										title={
											activeTask
												? `You can not update this field when the task: ${activeTask?.name} is in an active state`
												: ""
										}
										placement="top"
									>
										<Select
											disabled={activeTask}
											size="small"
											value={connectorInfo?.type || ""}
											onChange={(e) =>
												setConnectorInfo((prev) => ({
													...prev,
													type: e.target.value,
												}))
											}
											label="Type"
											labelId="Type"
											sx={{
												fontSize: "0.875rem",
												textAlign: "left",
												maxWidth: { sm: "142px" },
											}}
										>
											<MenuItem value={""}>
												<em>None</em>
											</MenuItem>
											<MenuItem value={"SOURCE"}>
												SOURCE
											</MenuItem>
											<MenuItem value={"DESTINATION"}>
												DESTINATION
											</MenuItem>
											<MenuItem value={"BOTH"}>
												BOTH
											</MenuItem>
										</Select>
									</Tooltip>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</>
	);
};

const industriesOption = [
	"Agriculture",
	"Mining",
	"Construction",
	"Manufacturing",
	"Utilities",
	"Transportation",
	"Information Technology",
	"Finance and Insurance",
	"Real Estate",
	"Healthcare",
	"Education",
	"Retail",
	"Wholesale",
	"Hospitality and Tourism",
	"Media and Entertainment",
	"Telecommunications",
	"Energy",
	"Professional Services",
	"Public Administration",
	"Nonprofit and Social Services",
];

export default ConnectorInfo;
